import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./App.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import JobBoard from "./pages/JobBoard/JobBoard";
import JobBoardPoei from "./pages/JobBoardPoei/JobBoardPoei";
import JobOffer from "./pages/JobOffer/JobOffer";
import JobOfferPoei from "./pages/JobOfferPoei/JobOfferPoei";
import Rdv from "./pages/Rdv/Rdv";
import RdvPoei from "./pages/RdvPoei/RdvPoei";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<JobBoard />} />
        <Route
          path="/:id/companies/:entreprise/jobs/:jobTitle"
          element={<JobOffer />}
        />
        <Route path="/poei" element={<JobBoardPoei />} />
        <Route
          path="poei/:id/:entreprise/jobs/:jobTitle"
          element={<JobOfferPoei />}
        />
        <Route path="/rdv" element={<Rdv />} />
        <Route path="/rdv-poei" element={<RdvPoei />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        {/*
          Add routes here by using example on:
          <Route path="{YOUR_PATH}" element={YOUR_COMPONENT} />
        */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
