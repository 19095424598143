import React from "react";
import "./CardHeader.scss";
import Arrow from "../DstIcon/Arrow";

const CardHeader = ({
  title,
  image,
  backgroundColor = "white",
  bootcamp = false,
  partTime = false,
  alternance = false,
  bootcampText = "Bootcamp",
  partTimeText = "Temps partiel",
  alternanceText = "Alternance",
  link,
}: {
  title?: string;
  image?: string;
  backgroundColor?: string;
  bootcamp?: boolean;
  partTime?: boolean;
  alternance?: boolean;
  bootcampText?: string;
  partTimeText?: string;
  alternanceText?: string;
  link?: string;
}) => {
  return (
    <div className="formation-item border-top border-end border-start ms-md-1">
      <a
        className="d-flex align-items-center text-decoration-none justify-content-between"
        href={`https://datascientest.com${link}`}
      >
        <div className="d-flex align-items-center">
          <img
            className={`img-fluid my-img ${backgroundColor}-background`}
            src={image}
            alt={title}
            height={20}
          />
          <h3 className="h3-style black-font ms-3 mb-0">{title}</h3>
        </div>
        <div className="d-none d-md-flex">
          <div className="fiche-tag d-flex pe-3 align-items-center me-5">
            {bootcamp && <span className="tag-bootcamp">{bootcampText}</span>}
            {bootcamp && partTime && <span className="tag-separate"> ou </span>}
            {partTime && <span className="tag-tp">{partTimeText}</span>}
            {alternance && (
              <span className="tag-alternance">{alternanceText}</span>
            )}
          </div>
          <Arrow
            orientation="right"
            height="2em"
            classes="custom"
            color="white"
          />
        </div>
      </a>
    </div>
  );
};

export default CardHeader;
