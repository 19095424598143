const Graduate = ({
  color,
  classes = "me-2",
  height = "20px",
}: {
  color?: string;
  classes?: string;
  height?: string;
}) => {
  return (
    <svg
      className={classes}
      width={height}
      height={height}
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.84126 10.0834C2.60977 10.0834 0.800903 8.0314 0.800903 5.50002C0.800903 2.96865 2.60977 0.916687 4.84126 0.916687C7.07275 0.916687 8.88161 2.96865 8.88161 5.50002C8.88161 8.0314 7.07275 10.0834 4.84126 10.0834ZM5.24529 5.50002V3.20835H4.43722V6.41669H6.86144V5.50002H5.24529Z"
        fill={color}
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default Graduate;
