import { v4 as uuidv4 } from "uuid";

export interface JobPoei {
  id: string;
  logo: string;
  entreprise: string;
  jobTitle: string;
  jobTag: string;
  jobTimeDay?: string;
  jobTimeHour: string;
  profileSought: string;
  goals: string;
  prerequisites: string;
  partners?: string[];
  programmes: { title: string; duration: string; description: string }[];
}

function generateGoalsHTML(goals: string[]): string {
  return `<ul>${goals.map((goal) => `<li>${goal}</li>`).join("")}</ul>`;
}

export const jobspoei: JobPoei[] = [
  {
    id: uuidv4(),
    logo: "datascientest.svg",
    entreprise: "DataScientest",
    jobTitle: "Cloud Data Engineer",
    jobTag: "Data",
    jobTimeDay: "57 jours",
    jobTimeHour: "400h",
    profileSought:
      "Bac +5 à composante scientifique = Master 2 informatique, mathématiques appliqués, statistiques",
    goals: generateGoalsHTML([
      "Concevoir et développer des logiciels et des applications.",
      "Maîtriser les données du Système d’Information.",
      "Analyser et représenter des données.",
      "Assurer la data quality.",
      "Mettre en œuvre la gestion et la gouvernance des données.",
      "Être sensibilisé à la veille technique et aux innovations et mises à jour des outils utilisés.",
      "Maîtriser les diverses bases de données.",
    ]),
    prerequisites: "Langage SQL + Système Linux",
    partners: [
      "bnp.svg",
      "bouygues.svg",
      "groupe-adp.svg",
      "credit-agricole.svg",
      "jems.svg",
    ],
    programmes: [
      {
        title: "Programmation",
        duration: "40h",
        description: `
          <p>Fondamentaux Python :</p>
          <ul>
            <li>Structure du langage Python</li>
            <li>Types de données et opérations</li>
            <li>Structures de contrôle (boucles et conditionnelles)</li>
            <li>Fonctions et modules Python</li>
            <li>Gestion des exceptions</li>
            <li>Manipulation de fichiers</li>
          </ul>
          <p>Python Orienté Objet :</p>
          <ul>
          <li>Concepts de base de la programmation orientée objet</li>
          <li>Classes et objets en Python</li>
          <li>Héritage et polymorphisme</li>
          <li>Encapsulation et abstraction</li>
          <li>Utilisation des classes dans la programmation Python</li>
        </ul>
        <p>Bash et Linux :</p>
        <ul>
          <li>Lignes de Commande Linux</li>
          <li>Redirection et Tubes (pipes)</li>
          <li>Variables d'Environnement</li>
          <li>Scripts Bash</li>
          <li>Utilisation de commandes système Linux</li>
          <li>Gestion des droits et des permissions</li>
        </ul>
        `,
      },
      {
        title: "Bases de données",
        duration: "50h",
        description: `
          <p>SQL :</p>
          <ul>
            <li>Modèles Relationnels</li>
            <li>Manipulation de Données (SELECT, INSERT, UPDATE, DELETE)</li>
            <li>Création et Gestion de Tables</li>
            <li>Jointures et Agrégations</li>
            <li>Indexation et Optimisation</li>
            <li>Transactions</li>
          </ul>
          <p>MongoDB :</p>
          <ul>
            <li>Stockage de Données sous forme de Documents JSON</li>
            <li>Utilisation de Collections</li>
            <li>Indexation et Requêtes</li>
            <li>Scalabilité Horizontale</li>
            <li>Gestion des Données Semi-Structurées</li>
          </ul>
          <p>Elasticsearch :</p>
          <ul>
            <li>Indexation de Données Textuelles</li>
            <li>Recherche Textuelle et Relevé de Score</li>
            <li>Agrégations et Analyse de Données</li>
            <li>Gestion de Logs et de Données Temporelles</li>
            <li>Utilisation de Fonctionnalités de Recherche Avancée</li>
          </ul>
          <p>Neo4j :</p>
          <ul>
            <li>Noeuds, Relations, Propriétés</li>
            <li>Requêtes Cypher pour les Graphes</li>
            <li>Utilisation dans la Représentation des Relations</li>
            <li>Applications dans les Domaines de la Recommandation, de la Détection de Fraude et de l'Analyse de Réseaux</li>
          </ul>
        `,
      },
      {
        title: "CI/CD",
        duration: "20h",
        description: `
          <p>Git :</p>
          <ul>
            <li>Système de gestion de version Git</li>
            <li>Initialisation d'un dépôt Git</li>
            <li>Approfondissement des concepts Git (branches, tag, merge)</li>
            </ul>
            <p>Github :</p>
            <ul>
              <li>Fonctionnalités majeures ( Fork, Pull Request, Issues)</li>
              <li>Pull et Push</li>
              <li>Github Actions et exemples applicatifs</li>
            <li>Quality Assurance</li>
          </ul>
        `,
      },
      {
        title: "Big Data Volume",
        duration: "50h",
        description: `
          <p>Hadoop :</p>
          <ul>
            <li>HDFS pour le stockage distribué</li>
            <li>MapReduce pour le traitement distribué</li>
          </ul>
          <p>Hive :</p>
          <ul>
            <li>HDFS</li>
            <li>SQL</li>
          </ul>
          <p>HBase :</p>
          <ul>
            <li>Hadoop</li>
          </ul>
          <p>Spark :</p>
          <ul>
            <li>Calcul distribué</li>
            <li>Spark avec Puthon et Scala</li>
          </ul>
        `,
      },
      {
        title: "Data Science",
        duration: "50h",
        description: `
          <p>Statistiques :</p>
          <ul>
            <li>Calcul de mesures: moyenne, écart-type</li>
            <li>Réalisation de tests statistiques pour prendre des décisions basées sur des données</li>
          </ul>
          <p>Machine Learning :</p>
          <ul>
            <li>Apprentissage à partir de données</li>
            <li>Prise des décisions autonomes</li>
            <li>L'entraînement des modèles de Machine Learning</li>
          </ul>
          <p>Matplotlib :</p>
          <ul>
            <li>Python</li>
            <li>Graphiques et visualisations à partir de données</li>
            <li>Business Intelligence</li>
          </ul>
        `,
      },
      {
        title: "Big Data Vitesse",
        duration: "20h",
        description: `
          <p>Architecture en Streaming :</p>
          <ul>
            <li>Traitement de données en temps réel</li>
          </ul>
          <p>Apache Kafka :</p>
          <ul>
            <li>Streaming distribué avec Kafka</li>
            <li>Gestion des paramétrages des Producers et des Consumers</li>
          </ul>
          <p>Spark Streaming :</p>
          <ul>
            <li>Mini-batch</li>
          </ul>
        `,
      },
      {
        title: "Automatisation et déploiement",
        duration: "60h",
        description: `
          <p>APIs :</p>
          <ul>
            <li>Méthodes HTTP et fonctions</li>
            <li>FastAPI et Flask</li>
            <li>OpenAPI</li>
          </ul>
          <p>Dockers :</p>
          <ul>
            <li>Conteneurisation et virtualisation</li>
            <li>Persistence des données</li>
            <li>Dockerfile, Dockerhub, docker-compose</li>
          </ul>
          <p>Apache Airflow :</p>
          <ul>
            <li>Orchestration et utilité</li>
            <li>DAG</li>
            <li>Opérateurs</li>
          </ul>
          <p>Kubernetes :</p>
          <ul>
            <li>Gérer des conteneurs</li>
            <li>Initialisation et Architecture</li>
            <li>Déployer une API avec Kubernetes</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "datascientest.svg",
    entreprise: "DataScientest",
    jobTitle: "Data Gouvernance - MDM",
    jobTag: "Data",
    jobTimeDay: "57 jours",
    jobTimeHour: "399h",
    profileSought: "Bac + 5 minimum",
    goals: generateGoalsHTML([
      "Maîtriser les fondamentaux du Master Data Management (MDM) pour gérer efficacement les données de référence.",
      "Concevoir et optimiser des bases de données relationnelles et des Datawarehouses.",
      "Programmer en Java et Python pour la manipulation et l'analyse de données.",
      "Mettre en œuvre des pratiques de gouvernance des données pour garantir la qualité, la sécurité et la conformité des données.",
      "Intégrer des données en utilisant Talend Open Studio.",
      "Créer des tableaux de bord interactifs.",
      "Appliquer les principes du RGPD pour garantir la conformité en matière de protection des données.",
      "Exploiter les technologies NoSQL pour gérer des données non relationnelles.",
    ]),
    prerequisites: "Expérience significative (2 ans minimum)",
    partners: ["jems.svg"],
    programmes: [
      {
        title: "Les fondamentaux du MDM",
        duration: "28h",
        description: `
        <p>Master Data Management (MDM) :</p>
          <ul>
            <li>Gestion centralisée</li>
            <li>Hiérarchie des données</li>
            <li>Consolidation et synchronisation</li>
            </ul>
            <p>Données de référence</p>
            <p>Qualité des données</p>
        `,
      },
      {
        title: "Bases de données relationnelles et Datawarehouses",
        duration: "56h",
        description: `
        <p>SQL (Structured Query Language) :</p>
          <ul>
            <li>Manipulation de données</li>
            <li>Requêtes SQL</li>
            <li>Création de schémas</li>
          </ul>
          <p>Modélisation de données :</p>
          <ul>
          <li>Modèles conceptuels</li>
          <li>Modèles logiques</li>
          <li>Modèles physiques</li>
          </ul>
        `,
      },
      {
        title: "Programmation avec Java et Python",
        duration: "28h",
        description: `
        <p>Java :</p>
          <ul>
            <li>Orienté objet</li>
            <li>Développement logiciel</li>
            </ul>

            <p>Python :</p>
            <ul>
            <li>Variables, Types</li>
            <li>Les opérateurs et leurs applications</li>
            <li>Boucles, fonctions</li>
            <li>Classes, modules</li>
            <li>Bibliothèques</li>
            </ul>
            <p>Programmation objet :</p>
            <ul>
            <li>Encapsulation, héritage, polymorphisme</li>
          </ul>
        `,
      },
      {
        title: "Gouvernance et qualité des données",
        duration: "42h",
        description: `
        <p>Gouvernance des données :</p>
          <ul>
          <li>Gestion stratégique des données</li>
          </ul>
          <p>Qualité des données :</p>
          <ul>
            <li>Impact sur la prise de décision</li>
            </ul>
            <p>Politiques de données :</p>
            <ul>
            <li>Règles et normes d'utilisation des données</li>
            <li>Encadre la collecte, le stockage et l'accès aux données</li>
            </ul>
        `,
      },
      {
        title: "Intégration des données avec Talend",
        duration: "42h",
        description: `
        <p>Talend Open Studio :</p>
          <ul>
          <li>Plateforme ETL (Extract, Transform, Load)</li>
          <li>Outil open source de gestion et d'intégration de données</li>
          <li>Utilisé pour l'extraction, la transformation et le chargement de données</li>
          </ul>
          <p>ETL (Extract, Transform, Load) :</p>
          <ul>
            <li>Processus de gestion de données</li>
            <li>Extraction de données à partir de sources diverses</li>
            <li>Transformation des données selon les besoins</li>
            <li>Chargement des données dans un entrepôt ou une base de données cible</li>
            </ul>
        `,
      },
      {
        title: "Business Intelligence",
        duration: "49h",
        description: `
        <p>Power BI :</p>
          <ul>
          <li>Data visualisation avec Power Query</li>
          <li>Langage DAX</li>
          <li>Création d'un tableau de bord</li>
          </ul>
          <p>SQL Server Reporting Services (SSRS) :</p>
          <ul>
          <li>Intégration avec des sources de données SQL Server</li>
          </ul>
          <p>Business Analytics :</p>
          <ul>
            <li>Utilisation de données pour comprendre les performances commerciales</li>
          </ul>
        `,
      },
      {
        title: "Big Data et RGPD",
        duration: "91h",
        description: `
        <p>Hadoop :</p>
          <ul>
          <li>Installation et configuration</li>
          <li>Stockage des données avec HDFS</li>
          <li>MapReduce</li>
          <li>Hive</li>
          </ul>
          <p>Règlement général sur la protection des données (RGPD) :</p>
          <ul>
          <li>RGPD et éthique dans la Data</li>
          </ul>
          <p>Stockage distribué :</p>
          <ul>
            <li>Gestion du volume et accès aux données</li>
          </ul>
        `,
      },
      {
        title: "Technologies NoSQL",
        duration: "21h",
        description: `
        <p>MongoDB :</p>
        <ul>
        <li>Base de données NoSQL</li>
          <li>Documents JSON</li>
          <li>Évolutivité horizontale et flexibilité de schémas</li>
          </ul>
          <p>Elasticsearch :</p>
          <ul>
          <li>Index et mode d'emploi</li>
          <li>Mapping</li>
          <li>Pré-traitement des données avec Ingest Node</li>
          <li>Extraction des données avec Text Analyzer</li>
          </ul>
          <p>Base de données non relationnelles :</p>
          <ul>
            <li>NoSQL</li>
          </ul>
        `,
      },
      {
        title: "Projet + soutenance",
        duration: "42h",
        description: "",
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "datascientest.svg",
    entreprise: "DataScientest",
    jobTitle: "Analytics Engineer",
    jobTag: "Data",
    jobTimeDay: "",
    jobTimeHour: "400h",
    profileSought: `Bac +5 à composante scientifique ou école de commerce`,
    goals: generateGoalsHTML([
      "Fournir des données propres, transformées et prêtes à être analysées.",
      "Appliquer les meilleures pratiques d'ingénierie logicielle au code analytique.",
      "Entretenir la documentation et la définition des données.",
      "Maîtriser les outils de Data Visualisation et être en capacité de sensibiliser les équipes business à leur usage.",
      "Concevoir, développer et étendre le code pour étendre le modèle dimensionnel d'entreprise.",
      "Mettre en œuvre la philosophie DataOps dans tout ce que vous faites.",
      "Obtenir la certification officielle Microsoft PL-300.",
    ]),
    prerequisites:
      "1 année d'expérience soit dans la BI ou plusieurs années d'expériences justifiant une bonne expertise métier",
    partners: [
      "orange.svg",
      "bcg.svg",
      "michelin.svg",
      "safran.svg",
      "capgemini.svg",
    ],
    programmes: [
      {
        title: "Introduction à Python",
        duration: "60H",
        description: `
          <p>Les fondamentaux de Python :</p>
          <ul>
            <li>Bases de Python</li>
            <li>Manipulation, visualisation, analyse de données</li>
            <li>Traitement de fichiers et dossiers</li>
            <li>Librairies spécialisées, projet pratique, gestion de l'environnement de développement.</li>
          </ul>
          <p>Pandas :</p>
          <ul>
            <li>Séries</li>
            <li>Importation et exportation de données</li>
            <li>Nettoyage des données</li>
          </ul>
          <p>Data Quality (Gestion de la qualité des données) :</p>
          <ul>
            <li>Intégrité et cohérence des données</li>
            <li>Nettoyage et normalisation</li>
            <li>Validation des données</li>
            <li>Complétude des données</li>
          </ul>
          <p>Introduction aux APIs :</p>
          <ul>
            <li>Endpoints et requêtes</li>
            <li>Méthodes HTTP</li>
            <li>Authentification</li>
            <li>Utilisation des réponses de l'API</li>
          </ul>
        `,
      },
      {
        title: "Data Integration",
        duration: "75H",
        description: `
          <p>Théorie fondamentale de l'intégration des données</p>
          <p>Système linux et Script Bash :</p>
          <ul>
            <li>Présentation des systèmes Linux</li>
            <li>Prise en main et utilisation d'un terminal</li>
            <li>Mise en place de scripts Bash</li>
          </ul>
          <p>Git/Github :</p>
          <ul>
            <li>Suivi collaboratif de projets</li>
            <li>Collaboration opensource</li>
          </ul>
          <p>Tests unitaires :</p>
          <ul>
            <li>Tests unitaires avec Pytest</li>
            <li>Tests d'intégration et leurs fonctions</li>
          </ul>
        `,
      },
      {
        title: "Bases de données",
        duration: "70H",
        description: `
          <p>Langage SQL :</p>
          <ul>
            <li>Requêtes SELECT</li>
            <li>Clauses WHERE, ORDER BY, GROUP BY et HAVING :</li>
            <li>Jointures (INNER JOIN, LEFT JOIN, RIGHT JOIN, FULL JOIN)</li>
            <li>Fonctions d'agrégation (SUM, AVG, COUNT, MAX, MIN)</li>
            <li>Sous-requêtes (Subqueries)</li>
            <li>Création et modification de tables (CREATE TABLE, ALTER TABLE)</li>
            <li>Index et contraintes :</li>
          </ul>
          <p>SQL Analytics</p>
          <ul>
            <li>Fenêtres (Window Functions)</li>
            <li>Fonctions d'analytique (Analytic Functions)</li>
            <li>Clauses OVER et PARTITION BY</li>
            <li>Fonctions temporelles (DATEDIFF, DATEADD, etc.)</li>
          </ul>
          <p>MangoDB :</p>
          <ul>
            <li>Documents et Collections</li>
            <li>Opérations CRUD (Create, Read, Update, Delete)</li>
            <li>Interrogation de données</li>
            <li>Index et agrégation</li>
            <li>Réplication et Sharding</li>
            <li>Import et Export de Données</li>
          </ul>
        `,
      },
      {
        title: "Data warehousing",
        duration: "80H",
        description: `
          <p>Snowflake / Big Query :</p>
          <ul>
            <li>Stockage de Données Massives</li>
            <li>Traitement Massivement Parallèle</li>
            <li>Traitement de Requêtes SQL</li>
            <li>Traitement en Temps Réel et Batch</li>
            <li>Intégration avec d'Autres Outils</li>
            <li>Sécurité et Gouvernance des Données</li>
          </ul>
          <p>Talend :</p>
          <ul>
            <li>ETL (Extraction, Transformation, Load)</li>
            <li>Connecteurs pour Diverses Sources de Données</li>
            <li>Transformation de Données</li>
            <li>Automatisation et Planification</li>
            <li>Gestion des Métadonnées</li>
          </ul>
          <p>dbt :</p>
          <ul>
            <li>Modélisation de Données</li>
            <li>Réutilisabilité et Modularité</li>
            <li>Intégration avec d'Autres Outils</li>
            <li>Documentation Automatisée</li>
          </ul>
        `,
      },
      {
        title: "High performance Analytics",
        duration: "60H",
        description: `
          <p>PySpark Analytics :</p>
          <ul>
            <li>Traitement Massivement Parallèle (MPP)</li>
            <li>API Python pour Spark</li>
            <li>Transformation et Actions</li>
            <li>Machine Learning avec MLlib</li>
            <li>Traitement de Graphes avec GraphFrames</li>
            <li>Streaming de Données avec Structured Streaming</li>
          </ul>
          <p>Apache Airflow :</p>
          <ul>
            <li>Workflow Orchestration</li>
            <li>Planification et Gestion des Tâches</li>
            <li>Intégration avec Diverses Sources de Données</li>
            <li>Surveillance et Gestion des Erreurs</li>
            <li>Planification Dynamique</li>
            <li>Extensions et Personnalisation</li>
          </ul>
        `,
      },
      {
        title: "Business Intelligence",
        duration: "75H",
        description: `
          <p>Power BI :</p>
          <ul>
            <li>Importation de Données</li>
            <li>Modélisation de Données</li>
            <li>Création de Visualisations</li>
            <li>Tableaux de Bord (Dashboards)</li>
            <li>Partage et Collaboration</li>
            <li>Analyse Avancée et DAX (Data Analysis Expressions)</li>
            <li>Intégration avec d'Autres Outils Microsoft</li>
          </ul>
          <p>Tableau :</p>
          <ul>
            <li>Importation et Connexion de Données</li>
            <li>Création de Tableaux de Bord et de Feuilles de Travail</li>
            <li>Visualisations Puissantes</li>
            <li>Tableau de Bord en Temps Réel</li>
            <li>Partage et Collaboration</li>
            <li>Analytique Avancée</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "datascientest.svg",
    entreprise: "DataScientest",
    jobTitle: "Data Scientist",
    jobTag: "Data",
    jobTimeDay: "",
    jobTimeHour: "400h",
    profileSought: `Bac + 5 en sciences`,
    goals: generateGoalsHTML([
      "Maîtriser les fondamentaux de la programmation en Python avec Numpy et Pandas.",
      "Créer des visualisations de données percutantes.",
      "Appliquer des modèles de machine learning pour la classification et la régression et le clustering.",
      "Manipuler et analyser des séries temporelles et extraire des informations significatives grâce au Text Mining.",
      "Gérer des données à grande échelle en utilisant SQL et les bases de données.",
      "Construire des modèles de Deep Learning avec TensorFlow et Keras. ",
      "Explorer le domaine des systèmes complexes et de l'IA.",
      "Comprendre le Reinforcement Learning et le Deep Reinforcement Learning pour prendre des décisions autonomes.",
    ]),
    prerequisites: "Bac +3, Bon niveau en mathématiques",
    partners: [
      "bnp.svg",
      "bouygues.svg",
      "groupe-adp.svg",
      "credit-agricole.svg",
    ],
    programmes: [
      {
        title: "Programmation",
        duration: "40h",
        description: `
          <p>Python</p>
          <ul>
            <li>Data Science</li>
            <li>Bibliothèques</li>
            <li>Analyse de données</li>
            <li>Machine learning</li>
          </ul>
          <p>Numpy + Pandas</p>
          <ul>
            <li>Manipulation de données</li>
            <li>Opérations numériques et tableaux multidimensionnels</li>
            <li>Manipulation et analyse de données tabulaires</li>
          </ul>
        `,
      },
      {
        title: "Dataviz",
        duration: "20h",
        description: `
          <p>Matplotlib</p>
          <ul>
            <li>Données en Python</li>
            <li>Graphiques statiques</li>
          </ul>
          <p>Seaborn</p>
          <ul>
            <li>Visualisation de données</li>
            <li>Matplotlib</li>
            <li>Données statistiques + graphiques complexes</li>
          </ul>
          <p>Bokeh</p>
          <ul>
            <li>Graphiques interactifs</li>
          </ul>
        `,
      },
      {
        title: "Machine Learning",
        duration: "30h",
        description: `
          <p>Modèles et algorithmes de classification</p>
          <ul>
            <li>Catégorisation des données</li>
            <li>Régression logistique, arbres de décision, forêts aléatoires</li>
          </ul>
          <p>Modèles avancés de classification</p>
          <ul>
            <li>Machines à vecteurs de support (SVM), et les méthodes d'ensemble avancées</li>
            <li>Réseaux de neurones</li>
          </ul>
          <p>Méthodes de clustering</p>
          <ul>
            <li>Clusters</li>
            <li>k-moyennes</li>
            <li>DBSCAN</li>
            <li>Regroupement hiérarchique</li>
          </ul>
          <p>Méthodes de régression</p>
          <ul>
            <li>Valeurs numériques en fonction de variables indépendantes</li>
            <li>Régression linéaire</li>
            <li>Régression polynomiale</li>
            <li>Réseaux de neurones pour la régression</li>
          </ul>
        `,
      },
      {
        title: "Machine Learning avancé",
        duration: "30h",
        description: `
          <p>Méthodes de réduction de dimension</p>
          <ul>
            <li>Analyse en composantes principales (PCA)</li>
            <li>Analyse factorielle, t-SNE</li>
          </ul>
          <p>Séries temporelles avec statsmodels</p>
          <ul>
            <li>Statsmodels</li>
            <li>Prévision des ventes</li>
            <li>Analyse de données financières</li>
          </ul>
          <p>Text mining</p>
          <ul>
            <li>Analyse de sentiments</li>
            <li>Catégorisation de documents</li>
            <li>Extraction d'entités</li>
          </ul>
          <p>Machine learning et théorie des graphes avec NetworkX</p>
          <ul>
            <li>Analyse de réseaux sociaux</li>
            <li>Recommandation</li>
            <li>Détection de communautés dans les graphes</li>
          </ul>
        `,
      },
      {
        title: "Data Engineering",
        duration: "30h",
        description: `
          <p>Langage SQL</p>
          <ul>
            <li>Bases de données relationnelles</li>
            <li>Extraction, mise à jour et manipulation de données</li>
          </ul>
          <p>Base de données</p>
          <ul>
            <li>Machine learning avec PySpark</li>
            <li>Clusters</li>
            <li>Algorithmes de machine learning à grande échelle</li>
          </ul>
        `,
      },
      {
        title: "Deep Learning",
        duration: "40h",
        description: `
          <p>TensorFlow et Keras</p>
          <ul>
            <li>Réseaux de neurones.</li>
            <li>Deep Learning.</li>
          </ul>
          <p>CNN (Convolutional Neural Network)</p>
          <ul>
            <li>Réseau de neurones profonds</li>
            <li>Traitement d'images et vision par ordinateur.</li>
            <li>Motifs spatiaux dans les données.</li>
          </ul>
          <p>RNN (Recurrent Neural Network)</p>
          <ul>
            <li>Données séquentielles.</li>
            <li>Séries temporelles</li>
            <li>Mémoire à court terme des données précédentes.</li>
          </ul>
          <p>GANs (Generative Adversarial Networks)</p>
          <ul>
            <li>Générateur</li>
            <li>Discriminateur.</li>
            <li>Génération d'images</li>
            <li>Synthèse de texte</li>
          </ul>
        `,
      },
      {
        title: "Systèmes complexes et IA",
        duration: "20h",
        description: `
          <p>Reinforcement Learning</p>
          <ul>
            <li>Récompense cumulative.</li>
          </ul>
          <p>Deep Reinforcement Learning</p>
          <ul>
            <li>Réseaux de neurones profonds.</li>
            <li>Espaces d'états et actions de grande dimension.</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "devuniversity.svg",
    entreprise: "DevUniversity",
    jobTitle: "Ingénieur DevOps",
    jobTag: "Développement, systèmes et réseaux",
    jobTimeDay: "11 semaines",
    jobTimeHour: "400h",
    profileSought: `Développeurs ou administrateurs systèmes et réseaux`,
    goals: generateGoalsHTML([
      "Écrire et gérer du code.",
      "Gérer le stockage des données à long terme avec des structures de données persistantes.",
      "Concevoir des composants pertinents dans le cadre de l'architecture.",
      "Déployer du code dans différents environnements, passant ainsi de la phase de développement à celle de l'application.",
      "Suivre, identifier et résoudre des problèmes tout au long du processus.",
      "Obtenir la certification AWS Solutions Architect Associate.",
    ]),
    prerequisites:
      "Posséder un diplôme ou titre RNCP de niveau Bac+2 en informatique et Bac+3 dans un domaine scientifique. Bonne maîtrise du langage Python et des systèmes Linux.",
    partners: [
      "orange.svg",
      "bcg.svg",
      "michelin.svg",
      "safran.svg",
      "capgemini.svg",
    ],
    programmes: [
      {
        title: "Développement d'applications avec Python",
        duration: "35h",
        description: `
          <p>Python :</p>
          <ul>
            <li>Automatisation des Tâches</li>
            <li>Développement d'Outils</li>
            <li>Manipulation de Données</li>
            <li>Intégration avec des API</li>
          </ul>
          <p>Git/Github :</p>
          <ul>
            <li>Contrôle de Version</li>
            <li>Collaboration d'Équipe</li>
            <li>Gestion de Projet</li>
            <li>Intégration Continue (CI)</li>
          </ul>
          <p>Agile/DevOps :</p>
          <ul>
            <li>Culture Collaborative</li>
            <li>Livraison Continue</li>
            <li>Automatisation</li>
            <li>Surveillance et Rétroaction</li>
          </ul>
          <p>CI/Github actions :</p>
          <ul>
            <li>Automatisation de la Construction</li>
            <li>Exécution de Tests Automatisés</li>
            <li>Déploiement Automatisé</li>
            <li>GitHub Actions</li>
            <li>Notifications et Rapports</li>
          </ul>
        `,
      },
      {
        title: "Systèmes Linux et Agilité",
        duration: "35h",
        description: `
          <p>Administration des systèmes Linux :</p>
          <ul>
            <li>Lignes de Commande</li>
            <li>Gestion des Paquets (apt, yum, etc.)</li>
            <li>Configuration Réseau</li>
            <li>Gestion des Services (systemd, init.d)</li>
            <li>Gestion des Utilisateurs et des Groupes</li>
            <li>Sauvegardes et Restaurations</li>
            <li>Sécurité et Gestion des Certificats</li>
          </ul>
          <p>NGINX :</p>
          <ul>
            <li>Configuration de Base de NGINX</li>
            <li>Configuration des Sites Virtuels (vHosts)</li>
            <li>Répartition de Charge (Load Balancing)</li>
            <li>Gestion des Certificats SSL/TLS</li>
            <li>Optimisation des Performances</li>
          </ul>
          <p>Agilité avec Jira :</p>
          <ul>
            <li>Création et Gestion des Éléments de Projet</li>
            <li>Planification Agile</li>
            <li>Suivi de Progrès</li>
            <li>Collaboration d'Équipe</li>
            <li>Intégration avec d'Autres Outils</li>
          </ul>
        `,
      },
      {
        title: "Bases de données",
        duration: "35h",
        description: `
          <p>Langage SQL :</p>
          <ul>
            <li>Syntaxe et commandes SQL de base</li>
            <li>Requêtes SELECT, INSERT, UPDATE, DELETE</li>
            <li>Jointures, sous-requêtes</li>
            <li>Agrégation et fonctions d'agrégation (SUM, AVG, COUNT, etc.)</li>
            <li>Création de tables, indexes, contraintes</li>
            <li>Transactions et verrouillages</li>
          </ul>
          <p>PostgreSQL :</p>
          <ul>
            <li>Installation et configuration de PostgreSQL</li>
            <li>Création de bases de données et d'utilisateurs</li>
            <li>Gestion des rôles et des permissions</li>
            <li>Utilisation de psql (ligne de commande PostgreSQL)</li>
            <li>Sauvegarde et restauration de bases de données</li>
            <li>Optimisation des performances</li>
            <li>Réplication et haute disponibilité</li>
          </ul>
          <p>MongoDB :</p>
          <ul>
            <li>Installation et configuration de MongoDB</li>
            <li>Utilisation de l'interface de ligne de commande (mongo shell)</li>
            <li>Schémas et modélisation des données (NoSQL)</li>
            <li>Opérations CRUD (Create, Read, Update, Delete)</li>
            <li>Indexation et performances</li>
            <li>Agrégation de données</li>
            <li>Réplication et sharding</li>
          </ul>
        `,
      },
      {
        title: "CI/CD",
        duration: "55h",
        description: `
          <p>Tests Unitaires :</p>
          <ul>
            <li>Cadres de test unitaire (ex : JUnit, Pytest)</li>
            <li>Intégration des tests unitaires dans le pipeline CI/CD</li>
          </ul>
          <p>GitLab :</p>
          <ul>
            <li>Création et gestion des dépôts Git</li>
            <li>Utilisation du système de contrôle de version Git</li>
            <li>Branching, merging et résolution des conflits</li>
            <li>Intégration de GitLab dans le processus CI/CD</li>
            <li>Création de pipelines CI/CD dans GitLab</li>
          </ul>
          <p>Docker :</p>
          <ul>
            <li>Concepts de conteneurisation</li>
            <li>Création de conteneurs Docker</li>
            <li>Utilisation de Dockerfile pour la création d'images</li>
            <li>Docker Hub et registres d'images</li>
            <li>Intégration de Docker dans le processus CI/CD</li>
          </ul>
          <p>Kubernetes :</p>
          <ul>
            <li>Concepts de l'orchestration de conteneurs</li>
            <li>Installation et configuration de Kubernetes</li>
            <li>Gestion des pods, services et déploiements</li>
            <li>Utilisation de YAML pour la définition d'applications dans Kubernetes</li>
            <li>Intégration de Kubernetes dans le processus CI/CD</li>
          </ul>
        `,
      },
      {
        title: "Cloud AWS",
        duration: "35h",
        description: `
          <p>AWS - EC2 & EBS :</p>
          <ul>
            <li>Provisionnement et configuration d'instances EC2 (Elastic Compute Cloud)</li>
            <li>Gestion des volumes de stockage EBS (Elastic Block Store)</li>
            <li>Création et gestion des images Amazon Machine Image (AMI)</li>
            <li>Utilisation de clés SSH pour l'accès sécurisé aux instances EC2</li>
            <li>Configuration de la sécurité et des groupes de sécurité</li>
            <li>Automatisation du déploiement des instances EC2</li>
          </ul>
          <p>AWS - Autoscaling - ELB :</p>
          <ul>
            <li>Configuration des groupes d'Auto Scaling pour gérer l'élasticité</li>
            <li>Utilisation des Launch Configurations et des Launch Templates</li>
            <li>Définition des politiques d'Auto Scaling basées sur les métriques</li>
            <li>Intégration d'Elastic Load Balancer (ELB) pour répartir la charge du trafic</li>
            <li>Gestion du trafic entre différentes zones de disponibilité (AZ)</li>
          </ul>
          <p>AWS - CloudWatch - Event Bridge :</p>
          <ul>
            <li>Surveillance et gestion des ressources AWS avec CloudWatch</li>
            <li>Configuration d'alarmes pour déclencher des actions basées sur des seuils</li>
            <li>Utilisation de métriques personnalisées et de tableaux de bord</li>
            <li>Utilisation de CloudWatch Events pour la gestion des événements</li>
            <li>Intégration de CloudWatch avec d'autres services AWS</li>
            <li>Définition de règles pour router les événements vers les cibles appropriées</li>
          </ul>
        `,
      },
      {
        title: "Automatisation",
        duration: "25h",
        description: `
          <p>Terraform :</p>
          <ul>
            <li>Infrastructure as Code (IaC) : Concepts et avantages</li>
            <li>Syntaxe de configuration de Terraform (HCL - HashiCorp Configuration Language)</li>
            <li>Création et gestion des ressources d'infrastructure (AWS, Azure, GCP, etc.)</li>
            <li>Utilisation des modules pour la réutilisabilité et la modularité</li>
            <li>Gestion des états de déploiement (state management) et verrouillage de l'état</li>
            <li>Intégration de Terraform avec d'autres outils DevOps</li>
          </ul>
          <p>Ansible :</p>
          <ul>
            <li>Automation as Code : Concepts et avantages</li>
            <li>Inventaire (Inventory) et configuration des hôtes (Hosts)</li>
            <li>Playbooks et Rôles : Définition des tâches à exécuter</li>
            <li>Utilisation des modules Ansible pour automatiser des actions (gestion de fichiers, exécution de commandes, etc.)</li>
            <li>Utilisation de Jinja2 pour la gestion des modèles</li>
            <li>Gestion des erreurs, des variables et des faits (facts)</li>
            <li>Intégration d'Ansible avec d'autres outils DevOps</li>
          </ul>
        `,
      },
      {
        title: "Monitoring",
        duration: "30h",
        description: `
          <p>Prometheus :</p>
          <ul>
            <li>Collecte de métriques à l'aide de cibles de scrapping</li>
            <li>Configuration de jobs de scrapping pour récupérer les métriques</li>
            <li>Utilisation du langage de requête PromQL pour interroger les métriques</li>
            <li>Création d'alertes basées sur les métriques avec des règles d'alerte</li>
            <li>Intégration avec d'autres systèmes et outils via des connecteurs</li>
          </ul>
          <p>Grafana :</p>
          <ul>
            <li>Connexion de Grafana à des sources de données, y compris Prometheus</li>
            <li>Création de tableaux de bord pour visualiser les métriques</li>
            <li>Personnalisation des visualisations et des panneaux (panels)</li>
            <li>Configuration d'alertes et de notifications basées sur les seuils</li>
            <li>Partage et collaboration en utilisant les tableaux de bord</li>
          </ul>
          <p>Datadog :</p>
          <ul>
            <li>Installation de l'agent Datadog pour la collecte de métriques</li>
            <li>Configuration des intégrations pour récupérer des métriques à partir de différentes sources</li>
            <li>Création de tableaux de bord personnalisés pour visualiser les métriques</li>
            <li>Configuration d'alertes basées sur les métriques et envoi de notifications</li>
            <li>Utilisation d'API Datadog pour l'intégration avec d'autres outils et systèmes</li>
          </ul>
        `,
      },
      {
        title: "Cloud DevOps",
        duration: "57h",
        description: `
          <p>AWS - Lambda :</p>
          <ul>
            <li>Surveillance des performances des fonctions Lambda</li>
            <li>Collecte de métriques telles que les invocations, la durée d'exécution, les erreurs, etc.</li>
            <li>Configuration d'alertes basées sur les métriques de performance</li>
            <li>Utilisation d'Amazon CloudWatch pour le monitoring</li>
          </ul>
          <p>AWS - CodePipeline :</p>
          <ul>
            <li>Surveillance des pipelines de déploiement</li>
            <li>Collecte de métriques liées au processus de déploiement</li>
            <li>Configuration d'alertes basées sur l'état et les performances du pipeline</li>
            <li>Utilisation d'Amazon CloudWatch pour le monitoring</li>
          </ul>
          <p>AWS - AppRunner :</p>
          <ul>
            <li>Surveillance des applications déployées sur AppRunner</li>
            <li>Collecte de métriques telles que les performances de l'application, la disponibilité, etc.</li>
            <li>Configuration d'alertes basées sur les métriques de performance</li>
            <li>Utilisation d'Amazon CloudWatch pour le monitoring</li>
          </ul>
          <p>AWS - CloudFormation :</p>
          <ul>
            <li>Surveillance des piles et des ressources créées à l'aide de CloudFormation</li>
            <li>Collecte de métriques liées aux mises à jour et aux changements dans les ressources</li>
            <li>Configuration d'alertes basées sur les événements de CloudFormation</li>
            <li>Utilisation d'Amazon CloudWatch pour le monitoring</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "devuniversity.svg",
    entreprise: "DevUniversity",
    jobTitle: "Développeur Java",
    jobTag: "Développement, systèmes et réseaux",
    jobTimeDay: "",
    jobTimeHour: "400h",
    profileSought: `Connaissances de base en programmation, Notions de base en HTML5 et CSS3, Connaissance en Java script, compréhension des concepts de base de données, Notions de base en développement Web, Compréhension des concepts de sécurité innformatique, connaissance en gestion de projet logiciel, Notions de base en architecture logicielle, connaissance de base des frameworks, ordinateur et environnement de développement.    `,
    goals: generateGoalsHTML([
      "Développer des applications en langage Java.",
      "Créer des pages web avec HTML5 et CSS3 et implémenter des fonctionnalités dynamiques à l’aide de JavaScript. ",
      "Maîtriser les fondements du développement natif JavaScript.",
      "Utiliser ReactJS pour construire des interfaces utilisateur réactives.",
      "Appliquer les concepts du framework AngularJS pour concevoir des applications web.",
      "Développer des servlets et des pages JSP pour créer des applications web Java dynamiques et interactives.",
      "Sécuriser les applications Java/JEE.",
      "Assurer la persistance des données de manière efficace.",
      "Développer des applications d'entreprise avec Spring 5.",
      "Créer des web services en Java pour faciliter la communication entre les applications.",
    ]),
    prerequisites:
      "Bac + 2 minimum, 1 an d'expérience dans l'informatique minimum",
    partners: ["cgi.svg"],
    programmes: [
      {
        title: "Méthodes Agiles",
        duration: "14h",
        description: `
          <p>Introduction à l'Agilité</p>
          <ul>
            <li>Concepts clés de Scrum, un framework agile populaire.</li>
            <li>Planification des itérations (sprints) et création du backlog du sprint.</li>
          </ul>
          <p>Gestion de Projet</p>
          <ul>
            <li>Comparaison des approches traditionnelles (cycle en V, cascade) avec les méthodes agiles.</li>
            <li>Choix de la méthodologie en fonction des besoins du projet et de l'environnement.</li>
            <li>Utilisation de pratiques agiles pour améliorer la flexibilité et l'adaptabilité du projet.</li>
          </ul>
        `,
      },
      {
        title: "Les bases de l'algorithmique",
        duration: "21h",
        description: `
          <p>Structures de données:</p>
          <ul>
            <li>Complexité</li>
            <li>Type de Données</li>
            <li>Opérations de Base</li>
          </ul>
          <p>Logique de programmation</p>
          <ul>
            <li>Algorithmes</li>
            <li>Structures de Contrôle</li>
            <li>Logique de Programmation</li>
          </ul>
        `,
      },
      {
        title: "Programmation Java",
        duration: "84h",
        description: `
          <p>Fondamentaux Java :</p>
          <ul>
            <li>Machine Virtuelle Java (JVM)</li>
            <li>Gestionnaire de Mémoire</li>
          </ul>
          <p>Programmation Orientée Objet :</p>
          <ul>
            <li>Objets et Classes</li>
            <li>Encapsulation</li>
            <li>Héritage et Polymorphisme</li>
          </ul>
        `,
      },
      {
        title: "Création de pages web",
        duration: "14h",
        description: `
          <p>HTML5</p>
          <p>CSS3</p>
        `,
      },
      {
        title: "Javascript, HTML dynamique",
        duration: "14h",
        description: `
          <p>JavaScript :</p>
          <ul>
            <li>Formulaires interactifs</li>
            <li>Animations</li>
            <li>Mises à jour en temps réel</li>
          </ul>
          <p>Programmation Côté Client :</p>
          <ul>
            <li>Expérience utilisateur</li>
            <li>Interaction avec HTML</li>
          </ul>
        `,
      },
      {
        title: "Les fondements du développement natif",
        duration: "14h",
        description: `
          <p>Développement JavaScript natif :</p>
          <ul>
            <li>Boucles</li>
            <li>Tableaux JavaScript</li>
          </ul>
          <p>Manipulation du DOM :</p>
          <ul>
            <li>'getElementById'.</li>
          </ul>
          <p>Événements JavaScript</p>
        `,
      },
      {
        title: "Le Framework Javascript de Facebook",
        duration: "21h",
        description: `
          <p>ReactJS :</p>
          <ul>
            <li>Composants réutilisables</li>
            <li>Composants React :</li>
            <li>Logique + vue</li>
          </ul>
          <p>Gestion d'État :</p>
          <ul>
            <li>Stats</li>
            <li>Props</li>
          </ul>
        `,
      },
      {
        title: "Le Framework Javascript de Google",
        duration: "21h",
        description: `
          <p>AngularJS</p>
          <ul>
            <li>DOM</li>
            <li>Données bi-directionnelles</li>
          </ul>
          <p>Architecture MVC :</p>
          <ul>
            <li>Le "Modèle"</li>
            <li>La "Vue"</li>
            <li>Le "Contrôleur"</li>
          </ul>
          <p>Routage</p>
        `,
      },
      {
        title: "Développement de Servlets et JSP",
        duration: "21h",
        description: `
          <p>Servlets :</p>
          <ul>
            <li>HTTP</li>
            <li>Web java côté serveur</li>
          </ul>
          <p>JavaServer Pages (JSP) :</p>
          <ul>
            <li>Java</li>
            <li>HTML</li>
            <li>Balises</li>
          </ul>
          <p>Développement Web Java :</p>
          <ul>
            <li>EE</li>
          </ul>
        `,
      },
      {
        title: "Java/JEE, sécurité des applications",
        duration: "35h",
        description: `
          <p>Sécurité des applications Java</p>
          <ul>
            <li>Authentification et autorisation</li>
            <li>Gestion des vulnérabilités</li>
          </ul>
        `,
      },
      {
        title: "Hibernate, mapping objet / relationnel",
        duration: "35h",
        description: `
          <p>Hibernate</p>
          <ul>
            <li>Mapping Objet-Relationnel (ORM)</li>
            <li>Persistance des Données</li>
          </ul>
        `,
      },
      {
        title: "Persistance des données",
        duration: "28h",
        description: `
          <p>Java Persistence API (JPA)</p>
          <ul>
            <li>BDD relationnelles</li>
          </ul>
          <p>Gestion de la Persistance</p>
          <ul>
            <li>Entités JPA</li>
          </ul>
        `,
      },
      {
        title: "Développer des applications d'entreprise",
        duration: "35h",
        description: `
          <p>Spring Framework :</p>
          <ul>
            <li>Inversion de contrôle</li>
            <li>Gestion des transactions</li>
            <li>Gestion de la configuration</li>
          </ul>
          <p>Injection de Dépendances</p>
        `,
      },
      {
        title: "Développer des web services",
        duration: "14h",
        description: `
          <p>Web services</p>
          <ul>
            <li>RESTful API</li>
            <li>REST (Representational State Transfer)</li>
            <li>HTTP standard</li>
          </ul>
          <p>SOAP</p>
          <ul>
            <li>XML</li>
          </ul>
        `,
      },
      {
        title: "Projet",
        duration: "28h",
        description: "",
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "devuniversity.svg",
    entreprise: "DevUniversity",
    jobTitle: "Développeur Cobol",
    jobTag: "Développement, systèmes et réseaux",
    jobTimeDay: "11 semaines",
    jobTimeHour: "400h",
    profileSought: `Bac +2/5`,
    goals: generateGoalsHTML([
      "Implémenter et gérer des réseaux TCP/IP pour assurer la connectivité et la communication dans un environnement informatique.",
      "Administrer un système d'exploitation Linux.",
      "Écrire des scripts en shell sous Unix/Linux pour automatiser des tâches courantes.",
      "Sécuriser un système Unix/Linux.",
      "Développer des applications COBOL avancées.",
      "Utiliser les ordonnanceurs Control-M et TWS pour automatiser et planifier des tâches informatiques.",
      "Gérer des fichiers VSAM en assurant un accès séquentiel et direct aux données stockées.",
      "Développer des applications interactives en environnement CICS.",
      "Utiliser le système de gestion de bases de données DB2 et le langage SQL pour interroger et gérer des bases de données.",
    ]),
    prerequisites:
      "1 an d'expérience minimum en tant que développeur + 1 diplôme dans une filière informatique",
    partners: [
      "orange.svg",
      "bcg.svg",
      "michelin.svg",
      "safran.svg",
      "capgemini.svg",
    ],
    programmes: [
      {
        title: "TCP/IP mise en oeuvre",
        duration: "21h",
        description: `
          <p>Protocole TCP/IP :</p>
          <ul>
            <li>Couches OSI et TCP/IP.</li>
            <li>Paquets de données, adresses IP et ports.</li>
            <li>Communication en réseau à l'aide de sockets.</li>
            <li>Utilisation des sockets pour l'échange de données entre applications sur des réseaux TCP/IP.</li>
            <li>Gestion des connexions TCP, notamment l'établissement et la fermeture des connexions.</li>
          </ul>
          <p>Réseaux :</p>
          <ul>
            <li>Concepts de base des réseaux informatiques.</li>
            <li>Topologies de réseau courantes (étoile, bus, anneau, maillé, etc.).</li>
            <li>Architecture client-serveur et communication client-serveur sur un réseau TCP/IP.</li>
            <li>Routage et commutation de données.</li>
            <li>Gestion des adresses IP, notamment les adresses IPv4 et IPv6.</li>
            <li>Sécurité des réseaux et notions de pare-feu, de VPN (Virtual Private Network) et de chiffrement des données.</li>
          </ul>
        `,
      },
      {
        title: "Linux, mise en oeuvre",
        duration: "28h",
        description: `
          <p>Système d'exploitation Linux :</p>
          <ul>
            <li>Notions sur la structure du système de fichiers et la hiérarchie des répertoires.</li>
            <li>Utilisation des utilisateurs, des groupes et des permissions.</li>
            <li>Gestion des processus et des tâches en arrière-plan.</li>
            <li>Configuration des services et des démons du système.</li>
          </ul>
          <p>Commandes Linux :</p>
          <ul>
            <li>Utilisation des commandes pour naviguer dans le système de fichiers (cd, ls, mkdir, etc.).</li>
            <li>Manipulation de fichiers et de répertoires (cp, mv, rm, etc.).</li>
            <li>Recherche et manipulation de contenu de fichiers (grep, awk, sed, etc.).</li>
            <li>Gestion des utilisateurs et des permissions (useradd, chmod, chown, etc.).</li>
          </ul>
          <p>Administration Linux :</p>
          <ul>
            <li>Configuration et gestion des utilisateurs et des groupes.</li>
            <li>Installation, mise à jour et suppression de logiciels.</li>
            <li>Gestion des services système et des processus.</li>
            <li>Gestion des mises à jour du système.</li>
            <li>Configuration du réseau et des interfaces réseau.</li>
            <li>Sécurité du système, y compris les pare-feu et les autorisations d'accès.</li>
          </ul>
        `,
      },
      {
        title: "Écriture de scripts sous UNIX/LINUX",
        duration: "21h",
        description: `
          <p>Shell Unix/Linux :</p>
          <ul>
            <li>Compréhension des shells Unix/Linux tels que Bash, Bourne Shell, Korn Shell, etc.</li>
            <li>Utilisation de l'interface en ligne de commande (CLI) et des interpréteurs de commandes.</li>
            <li>Connaissances de base sur les variables d'environnement et les variables du shell.</li>
          </ul>
          <p>Scripting Shell :</p>
          <ul>
            <li>Utilisation des commandes, des boucles, des conditions et des fonctions.</li>
            <li>Passage d'arguments et de paramètres aux scripts.</li>
            <li>Gestion des erreurs et des retours d'exécution.</li>
            <li>Création de scripts pour automatiser des tâches courantes.</li>
          </ul>
        `,
      },
      {
        title: "Sécurisation d'un système UNIX/LINUX",
        duration: "21h",
        description: `
          <p>Sécurité des systèmes Unix/Linux :</p>
          <ul>
            <li>Menaces et des vulnérabilités courantes.</li>
            <li>Gestion des correctifs de sécurité et des mises à jour du système.</li>
            <li>Utilisation de mécanismes de sécurité comme SELinux, AppArmor, etc.</li>
            <li>Configuration du pare-feu et des règles de sécurité.</li>
          </ul>
          <p>Gestion des autorisations :</p>
          <ul>
            <li>Modèle de sécurité basé sur les autorisations (permissions).</li>
            <li>Gestion des permissions pour les utilisateurs et les groupes.</li>
            <li>Chmod et Chown</li>
            <li>ACL (Access Control Lists)</li>
            <li>Privilèges et rôles.</li>
          </ul>
        `,
      },
      {
        title: "Algorithmique et programmation structurée",
        duration: "21h",
        description: `
          <p>Algorithmique :</p>
          <ul>
            <li>Séquences, boucles, conditionnelles.</li>
            <li>Complexité temporelle et spatiale.</li>
            <li>Algorithmes de tri, de recherche et autres algorithmes classiques.</li>
            <li>Application des algorithmes dans la résolution de problèmes concrets.</li>
          </ul>
          <p>Programmation structurée :</p>
          <ul>
            <li>Programmation structurée.</li>
            <li>Boucles (for, while) et les conditionnelles (if-else).</li>
            <li>Fonctions et procédures.</li>
            <li>Évitement de la programmation avec des sauts non structurés (goto).</li>
            <li>Programmation structurée en COBOL.</li>
          </ul>
        `,
      },
      {
        title: "Initiation au Cobol",
        duration: "35h",
        description: `
          <p>Langage de programmation COBOL :</p>
          <ul>
            <li>Division</li>
            <li>Sections</li>
            <li>Paragraphes</li>
          </ul>
          <p>Développement COBOL de base :</p>
          <ul>
            <li>Identification</li>
            <li>Environnement</li>
            <li>Données</li>
            <li>Procédure.</li>
            <li>Paragraphe</li>
          </ul>
        `,
      },
      {
        title: "Programmation Cobol avancée",
        duration: "35h",
        description: `
          <p>Programmation COBOL avancée :</p>
          <ul>
            <li>Boucles complexes</li>
            <li>Conditionnelles avancées</li>
            <li>Gestion des transactions et contrôle de la concurrence.</li>
            <li>Sous-programmes (appels, communication, etc.).</li>
            <li>Tableaux et structures de données complexes.</li>
          </ul>
          <p>Développement COBOL avancé :</p>
          <ul>
            <li>Gestion des erreurs et des exceptions de manière avancée.</li>
            <li>Techniques de débogage avancées pour les applications COBOL.</li>
          </ul>
        `,
      },
      {
        title: "Z/OS JCL et TSO/ISPF",
        duration: "35h",
        description: `
          <p>JCL (Job Control Language) :</p>
          <ul>
            <li>Environnements mainframe</li>
          </ul>
          <p>TSO (Time Sharing Option) :</p>
          <ul>
            <li>Datasets et les tâches.</li>
          </ul>
          <p>ISPF (Interactive System Productivity Facility) :</p>
          <ul>
            <li>Édition, création et gestion.</li>
            <li>Comparaison et recherche dans ISPF.</li>
            <li>Personnalisation et configuration</li>
          </ul>
        `,
      },
      {
        title: "Ordonnanceurs",
        duration: "35h",
        description: `
          <p>CONTROL-M :</p>
          <ul>
            <li>Dépendances et calendriers.</li>
            <li>Surveillance et gestion des workflows.</li>
          </ul>
          <p>Ordonnanceur TWS (Tivoli Workload Scheduler) :</p>
          <ul>
            <li>Plans de production</li>
            <li>Règles et dépendances</li>
            <li>Traitements batchs + opérations système.</li>
          </ul>
          <p>Automatisation et planification</p>
        `,
      },
      {
        title: "VSAM initiation et mise en pratique",
        duration: "35h",
        description: `
          <p>Gestion de fichiers VSAM :</p>
          <ul>
            <li>Introduction à VSAM (Virtual Storage Access Method)</li>
            <li>Avantages</li>
            <li>KSDS (Key-Sequenced Data Set)</li>
            <li>RRDS (Relative Record Data Set)</li>
            <li>ESDS (Entry-Sequenced Data Set).</li>
            <li>Index</li>
          </ul>
          <p>Accès séquentiel et direct :</p>
          <ul>
            <li>Ordre de lecture</li>
            <li>Lecture en fonction d'une clé ou d'une adresse.</li>
          </ul>
        `,
      },
      {
        title: "CICS: présentation du moniteur transactionnel",
        duration: "35h",
        description: `
          <p>Moniteur transactionnel :</p>
          <ul>
            <li>Gestion des ressources et de la concurrence dans un environnement transactionnel.</li>
            <li>Compréhension de la tolérance aux pannes et de la reprise après sinistre dans un environnement transactionnel.</li>
          </ul>
          <p>Développement d'applications CICS :</p>
          <ul>
            <li>Développement d'applications COBOL pour CICS en utilisant les outils appropriés.</li>
            <li>Intégration de programmes COBOL dans un environnement CICS.</li>
          </ul>
        `,
      },
      {
        title: "Bases de données DB2 et langage SQL",
        duration: "35h",
        description: `
          <p>Système de gestion de bases de données DB2 :</p>
          <ul>
            <li>SQL (Structured Query Language) :</li>
            <li>Requêtes SQL pour récupérer, mettre à jour et supprimer des données.</li>
            <li>Utilisation des clauses SQL telles que SELECT, WHERE, JOIN, GROUP BY, ORDER BY, etc.</li>
            <li>Création de tables, d'index et d'autres objets de base de données avec SQL.</li>
          </ul>
          <p>Bases de données :</p>
          <ul>
            <li>Bases de données relationnelles</li>
            <li>Bases de données DB2</li>
            <li>SQL</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "devuniversity.svg",
    entreprise: "DevUniversity",
    jobTitle: "Administrateur systèmes, réseaux et cloud",
    jobTag: "Développement, systèmes et réseaux",
    jobTimeDay: "",
    jobTimeHour: "400h",
    profileSought: `Bac + 3 en informatique`,
    goals: generateGoalsHTML([
      "Administrer efficacement les systèmes Windows et Linux.",
      "Gérer les réseaux locaux (LAN) et étendus (WAN) pour assurer la connectivité.",
      "Mettre en place et gérer des environnements de virtualisation avec VMWare, Proxmox et AWS.",
      "Gérer les parcs informatiques en appliquant la méthodologie ITIL, en utilisant des outils tels que ServiceNow, MDT et SolarWinds.",
      "Assurer la sécurité des systèmes Windows et Linux.",
      "Automatiser les tâches d'administration avec Powershell, Python et Ansible.",
      "Concevoir et mettre en place des infrastructures informatiques, y compris des environnements de sandbox et des architectures hybrides.",
      "Élaborer des solutions pour répondre aux besoins de l'entreprise en matière d'infrastructure et de services informatiques.",
    ]),
    prerequisites: "Bac + 2, 1 an d'expérience en IT minimum",
    programmes: [
      {
        title: "Fondamentaux",
        duration: "30h",
        description: `
          <p>Réseaux :</p>
          <ul>
            <li>Architecture des réseaux</li>
            <li>Protocoles de communication</li>
            <li>Adressage IP</li>
            <li>Configuration des réseaux</li>
          </ul>
          <p>Systèmes d'Exploitation :</p>
          <ul>
            <li>Principes de fonctionnement</li>
            <li>Gestion des ressources, mémoire, processus</li>
          </ul>
          <p>Base de Données :</p>
          <ul>
            <li>Utilisation et gestion</li>
          </ul>
        `,
      },
      {
        title: "Administration des systèmes et des réseaux",
        duration: "60h",
        description: `
          <p>Systèmes Windows :</p>
          <ul>
            <li>Powershell</li>
            <li>Active Directory</li>
            <li>Microsoft System Center Configuration Manager (SCCM)</li>
          </ul>
          <p>Bash :</p>
          <ul>
            <li>Ansible</li>
            <li>Docker</li>
          </ul>
          <p>Réseaux LAN et WAN :</p>
          <ul>
            <li>Wireshark</li>
            <li>Cisco Packet Tracer</li>
            <li>Nagios</li>
          </ul>
        `,
      },
      {
        title: "Virtualisation et cloud",
        duration: "80h",
        description: `
          <p>VMware :</p>
          <ul>
            <li>VMware vSphere</li>
            <li>VMware Workstation</li>
            <li>VMware vRealize Suite</li>
          </ul>
          <p>Proxmox :</p>
          <ul>
            <li>Proxmox Virtual Environment (Proxmox VE)</li>
            <li>Proxmox Backup Server</li>
            <li>Proxmox Mail Gateway</li>
          </ul>
          <p>Cloud AWS (Amazon Web Services) :</p>
          <ul>
            <li>Amazon EC2 (Elastic Compute Cloud)</li>
            <li>Amazon S3 (Simple Storage Service)</li>
            <li>Amazon RDS (Relational Database Service)</li>
          </ul>
        `,
      },
      {
        title: "Gestion des parcs informatiques",
        duration: "60h",
        description: `
          <p>Méthode ITIL (Information Technology Infrastructure Library) :</p>
          <ul>
            <li>ServiceNow</li>
            <li>MDT (Microsoft Deployment Toolkit)</li>
          </ul>
          <p>ServiceNow :</p>
          <ul>
            <li>Incident Management</li>
            <li>Change Management</li>
          </ul>
          <p>MDT (Microsoft Deployment Toolkit) :</p>
          <ul>
            <li>Image Deployment</li>
            <li>Task Sequences</li>
          </ul>
          <p>SolarWinds :</p>
          <ul>
            <li>SolarWinds Network Performance Monitor (NPM)</li>
            <li>SolarWinds Server & Application Monitor (SAM)</li>
          </ul>
        `,
      },
      {
        title: "Sécurité des systèmes d'information",
        duration: "70h",
        description: `
          <p>Sécurité des systèmes Windows et Linux :</p>
          <ul>
            <li>Antivirus et antimalware</li>
            <li>Gestion des correctifs (patch management)</li>
          </ul>
          <p>Kali Linux :</p>
          <ul>
            <li>Outils de test de pénétration (Penetration Testing Tools)</li>
            <li>Audits de sécurité (Security Auditing)</li>
          </ul>
          <p>Sécurité dans le cloud :</p>
          <ul>
            <li>Gestion des identités et des accès (Identity and Access Management, IAM)</li>
            <li>Pare-feu de nouvelle génération (Next-Generation Firewalls)</li>
          </ul>
        `,
      },
      {
        title: "Script et automatisation",
        duration: "50h",
        description: `
          <p>PowerShell :</p>
          <ul>
            <li>Interpréteur de commandes</li>
            <li>Langage de script</li>
            <li>Gestion de la configuration</li>
          </ul>
          <p>Automatisation avec Python :</p>
          <ul>
            <li>Ansible</li>
            <li>Déploiement d'une application et de ses configurations via des playbooks</li>
            <li>Déploiement des composants de l'application</li>
            <li>Création et gestion des tests via Molecule</li>
            <li>Création d'un coffre-fort sécurisant les données sensibles</li>
          </ul>
        `,
      },
      {
        title:
          "Conception, mise en place et gestion de l'infrastructure informatique",
        duration: "50h",
        description: `
          <p>Sandbox Cloud :</p>
          <ul>
            <li>AWS (Amazon Web Services) Sandbox, Azure Sandbox, Google Cloud Playground</li>
            <li>Isolation, tests sans risque, clones d'environnements</li>
          </ul>
          <p>Architecture Hybride :</p>
          <ul>
            <li>AWS Outposts, Azure Arc, VMware Cloud on AWS</li>
            <li>Intégration de ressources sur site et dans le cloud, gestion unifiée</li>
          </ul>
          <p>Conception et Mise en Place de l'Infrastructure :</p>
          <ul>
            <li>VMware vSphere, Microsoft Hyper-V, Kubernetes</li>
            <li>Planification des ressources, automatisation, haute disponibilité</li>
          </ul>
          <p>Intégration des Services :</p>
          <ul>
            <li>Docker Compose, Ansible, Kubernetes Service Discovery</li>
            <li>Orchestration, automatisation des tâches, API</li>
          </ul>
          <p>Sécurité et Gestion des Accès :</p>
          <ul>
            <li>Active Directory, Okta, Duo Security</li>
            <li>Authentification, autorisation, gestion des certificats, surveillance des journaux</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "devuniversity.svg",
    entreprise: "DevUniversity",
    jobTitle: "Administrateur Hadoop",
    jobTag: "Développement, systèmes et réseaux",
    jobTimeDay: "",
    jobTimeHour: "400h",
    profileSought: `Bac + 3 en informatique`,
    goals: generateGoalsHTML([
      "Mettre en œuvre des méthodes agiles de gestion de projet pour favoriser la flexibilité et l'adaptation dans le développement logiciel.",
      "Maîtriser le système d'exploitation Unix.",
      "Automatiser des tâches avec des scripts shell.",
      "Implémenter le contrôle de versions Git pour gérer le code source de manière collaborative et efficace.",
      "Développer des applications en Python.",
      "Automatiser la gestion des serveurs avec Ansible en utilisant l'Infrastructure as Code (IaC) pour déployer et configurer des infrastructures.",
      "Orchestrer la configuration de l'infrastructure en utilisant Terraform.",
      "Développer des applications Big Data avec Apache Hadoop.",
      "Administrer Apache Kafka, gérer les brokers et les topics, et développer des producteurs Kafka pour l'ingestion de flux de données en temps réel.",
      "Analyser et exploiter des données de manière efficace avec ELK.",
    ]),
    prerequisites: "Bac + 2, 1 an d'expérience en IT minimum",
    partners: ["jems.svg"],
    programmes: [
      {
        title: "Méthodes agiles et gestion de projet",
        duration: "14h",
        description: `
          <p>Introduction à l'Agilité :</p>
          <ul>
            <li>Concepts clés de Scrum, un framework agile populaire.</li>
            <li>Planification des itérations (sprints) et création du backlog du sprint.</li>
          </ul>
          <p>Gestion de Projet :</p>
          <ul>
            <li>Comparaison des approches traditionnelles (cycle en V, cascade) avec les méthodes agiles.</li>
            <li>Choix de la méthodologie en fonction des besoins du projet et de l'environnement.</li>
            <li>Utilisation de pratiques agiles pour améliorer la flexibilité et l'adaptabilité du projet.</li>
          </ul>
        `,
      },
      {
        title: "UNIX",
        duration: "35h",
        description: `
          <p>Système d'Exploitation Unix :</p>
          <ul>
            <li>Shell, système de fichiers, etc.</li>
            <li>Linux, FreeBSD</li>
            <li>Multi-utilisateur, multitâche et le modèle de permissions.</li>
          </ul>
          <p>Ligne de Commande Unix :</p>
          <ul>
            <li>Apprentissage des commandes de base pour naviguer et interagir avec le système.</li>
            <li>Utilisation des commandes pour la gestion des fichiers et des répertoires (ls, cd, mkdir, etc.).</li>
            <li>Manipulation des fichiers (cp, mv, rm, cat, etc.) et redirection des flux (>, >>, |, etc.).</li>
            <li>wildcards, alias</li>
          </ul>
          <p>Administration Unix :</p>
          <ul>
            <li>Gestion des utilisateurs et des groupes, ainsi que des permissions d'accès.</li>
            <li>Configuration du réseau, des interfaces et des protocoles.</li>
            <li>Gestion des services et des démons du système.</li>
            <li>Sauvegarde et restauration des données, gestion des mises à jour et des correctifs.</li>
          </ul>
        `,
      },
      {
        title: "Script Shell",
        duration: "21h",
        description: `
          <p>Automatisation :</p>
          <ul>
            <li>Choix des outils et des scripts appropriés.</li>
          </ul>
          <p>Ligne de Commande "Uber" :</p>
          <ul>
            <li>"ligne de commande uber" ou "supercommande".</li>
            <li>Shell</li>
          </ul>
        `,
      },
      {
        title: "Le contrôle de versions",
        duration: "14h",
        description: `
          <p>Git, contrôle des versions :</p>
          <ul>
            <li>Dépôt, commit, branche, fusion</li>
            <li>Méthodes de contrôle : centralisé et décentralisé.</li>
          </ul>
          <p>Gestion de Code Source :</p>
          <ul>
            <li>Pratiques de fusion pour intégrer les changements en toute sécurité dans le code source principal.</li>
          </ul>
        `,
      },
      {
        title: "Programmation objet en Python",
        duration: "35h",
        description: `
          <p>Python :</p>
          <ul>
            <li>Syntaxe de base de Python : variables, opérations, structures de contrôle, fonctions, etc.</li>
            <li>Utilisation des bibliothèques et modules Python pour étendre les fonctionnalités du langage.</li>
          </ul>
          <p>Programmation Orientée Objet (POO) :</p>
          <ul>
            <li>Classes et d'objets en Python.</li>
            <li>Héritage</li>
            <li>Encapsulation</li>
            <li>Polymorphisme</li>
          </ul>
          <p>Développement Python :</p>
          <ul>
            <li>La POO dans Python.</li>
            <li>SOLID (Single Responsibility, Open/Closed, Liskov Substitution, Interface Segregation, Dependency Inversion)</li>
          </ul>
        `,
      },
      {
        title: "Automatisation avec Ansible",
        duration: "21h",
        description: `
          <p>Ansible :</p>
          <ul>
            <li>Playbooks, rôles, modules, inventaire, etc.</li>
          </ul>
          <p>Automatisation de la Gestion des Serveurs</p>
          <ul>
            <li>Infrastructure as Code (IaC)</li>
            <li>Ansible pour fichiers de configuration.</li>
            <li>Reproductibilité, flexibilité et efficacité.</li>
          </ul>
        `,
      },
      {
        title: "Orchestration avec Terraform",
        duration: "14h",
        description: `
          <p>Terraform :</p>
          <ul>
            <li>Ressources, fournisseurs, états, etc.</li>
          </ul>
          <p>IaC avec Terraform :</p>
          <ul>
            <li>Reproductibilité et cohérence</li>
            <li>Standardisation</li>
          </ul>
          <p>Orchestration d'Infrastructure :</p>
          <ul>
            <li>Automatisation et coordination</li>
            <li>Déploiement + configuration</li>
            <li>Environnements complexes et inter-dépendants</li>
          </ul>
        `,
      },
      {
        title: "Hadoop: l'écosystème",
        duration: "7h",
        description: `
          <p>Écosystème Hadoop :</p>
          <ul>
            <li>Technologies et frameworks interconnectés.</li>
          </ul>
          <p>Composants Hadoop :</p>
          <ul>
            <li>Hadoop Distributed File System (HDFS)</li>
            <li>MapReduce</li>
            <li>YARN (Yet Another Resource Negotiator)</li>
            <li>Apache Hive</li>
            <li>Apache Pig</li>
            <li>Apache Spark</li>
            <li>Apache HBase</li>
          </ul>
        `,
      },
      {
        title: "Développer des applications pour le Big Data",
        duration: "28h",
        description: `
          <p>Apache Hadoop :</p>
          <ul>
            <li>Panne, Scalabilité et Flexibilité.</li>
            <li>HDFS (Hadoop Distributed File System), MapReduce et YARN.</li>
          </ul>
          <p>Architecture Hadoop :</p>
          <ul>
            <li>Stockage et traitement</li>
            <li>YARN</li>
          </ul>
        `,
      },
      {
        title: "Hadoop: installation, administration, sécurité et monitoring",
        duration: "63h",
        description: `
          <p>Installation d'Hadoop :</p>
          <ul>
            <li>Administration d'Hadoop :</li>
            <li>Accès et permissions</li>
            <li>Nœuds du cluster</li>
          </ul>
          <p>Sécurité dans Hadoop :</p>
          <ul>
            <li>Enjeux</li>
            <li>Authentification, Autorisation et Confidentialité des données.</li>
          </ul>
          <p>Surveillance dans Hadoop :</p>
          <ul>
            <li>Surveillance</li>
            <li>Mesures correctives</li>
            <li>Alertes et disponibilité</li>
          </ul>
        `,
      },
      {
        title: "Apache Kafka",
        duration: "63h",
        description: `
          <p>Administration et Broker/Topic :</p>
          <ul>
            <li>Brokers Kafka, topics et partitions.</li>
            <li>Surveiller et diagnostiquer les problèmes de performance.</li>
          </ul>
          <p>Kafka Producer</p>
          <p>Apache Kafka et Apache NiFi :</p>
          <ul>
            <li>Synergies</li>
            <li>Ingestion, transformation et traitement des flux de données.</li>
          </ul>
        `,
      },
      {
        title: "Indexation, recherche et visualisation des données",
        duration: "35h",
        description: `
          <p>ELK (Administration et Exploitation) :</p>
          <ul>
            <li>Elasticsearch, Logstash et Kibana.</li>
            <li>Installation, configuration et gestion d'Elasticsearch</li>
            <li>Elasticsearch pour des opérations d'indexation, de recherche et d'analyse sur les données.</li>
            <li>Insights à partir des données récoltées</li>
          </ul>
          <p>Logstash :</p>
          <ul>
            <li>Ingestion, Transformation et Enrichissement des données provenant de diverses sources.</li>
          </ul>
          <p>Kibana :</p>
          <ul>
            <li>Tableaux de bord interactifs et visualisation.</li>
            <li>Configuration des visualisations, des graphiques, des cartes géographiques et des tableaux de bord dans Kibana.</li>
          </ul>
        `,
      },
      {
        title: "Initiation à la distribution MapR",
        duration: "21h",
        description: `
          <p>MapR :</p>
          <ul>
            <li>Initiation à la distribution MapR.</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "devuniversity.svg",
    entreprise: "DevUniversity",
    jobTitle: "Administrateur systèmes Linux",
    jobTag: "Développement, systèmes et réseaux",
    jobTimeDay: "",
    jobTimeHour: "400h",
    profileSought: `Développeurs C`,
    goals: generateGoalsHTML([
      "Mettre en œuvre le protocole TCP/IP.",
      "Programmer en langage C pour développer des logiciels et des solutions système.",
      "Administrer les systèmes Linux et obtenir les certifications LPI 101 et LPI 102. ",
      "Administrer des systèmes Red Hat et optimiser les performances pour une utilisation efficace.",
      "Gérer la sécurité des systèmes et des réseaux en identifiant et en remédiant aux vulnérabilités.",
      "Déployer des mesures de détection d'intrusion et gérer les alertes de sécurité, en fonctionnant comme un Security Operations Center (SOC).",
      "Virtualiser des serveurs avec VMware vSphere, créer et gérer des machines virtuelles.",
      "Créer et administrer des conteneurs virtuels d'applications avec Docker, facilitant le déploiement et la gestion des applications.",
      "Automatiser la gestion des serveurs en utilisant Ansible, une approche d'Infrastructure as Code (IaC).",
    ]),
    prerequisites: "Bac +2/5, expérience 1 an XP minimum",
    partners: ["alten.svg"],
    programmes: [
      {
        title: "TCP/IP, mise en oeuvre",
        duration: "28h",
        description: `
          <p>Protocole TCP/IP :</p>
          <ul>
            <li>Introduction</li>
            <li>Protocole de Transports et Applications</li>
            <li>Sécurité et Dépannage</li>
          </ul>
          <p>Réseaux :</p>
          <ul>
            <li>Architecture des réseaux</li>
            <li>Protocoles de communication</li>
            <li>Adressage IP</li>
            <li>Configuration des réseaux</li>
          </ul>
        `,
      },
      {
        title: "Programmation",
        duration: "28h",
        description: `
          <p>Langage de Programmation C :</p>
          <ul>
            <li>Pointeurs</li>
            <li>Tableaux</li>
            <li>Gestion de la mémoire</li>
          </ul>
          <p>Développement Logiciel :</p>
          <ul>
            <li>Conception</li>
            <li>Programmation</li>
            <li>Test</li>
          </ul>
          <p>Programmation Système :</p>
        `,
      },
      {
        title: "Linux, initiation",
        duration: "35h",
        description: `
          <p>Système d'Exploitation :</p>
          <ul>
            <li>Noyau (Kernel)</li>
            <li>Gestionnaire de fichiers (File Manager)</li>
            <li>Interface utilisateur (User Interface)</li>
          </ul>
          <p>Commandes :</p>
          <ul>
            <li>Syntaxe</li>
            <li>Options</li>
            <li>Arguments</li>
          </ul>
          <p>LPI 101 (Certification) :</p>
          <ul>
            <li>Linux Essentials</li>
            <li>Administration de base</li>
            <li>Sécurité et maintenance</li>
          </ul>
        `,
      },
      {
        title: "Linux, administration",
        duration: "35h",
        description: `
          <p>Administration :</p>
          <ul>
            <li>Gestion de systèmes</li>
            <li>Politiques de sécurité</li>
            <li>Optimisation des performances</li>
          </ul>
          <p>Gestion de Systèmes :</p>
          <ul>
            <li>Supervision</li>
            <li>Maintenance</li>
            <li>Amélioration</li>
          </ul>
          <p>LPI 102 (Certification) :</p>
          <ul>
            <li>Configuration réseau</li>
            <li>Gestion des services</li>
            <li>Sécurité et résolution de problèmes</li>
          </ul>
        `,
      },
      {
        title: "Administration système Redhat",
        duration: "35h",
        description: `
          <p>Système d'Exploitation et Configuration Red Hat :</p>
          <ul>
            <li>Paramètres spécifiques à Red Hat</li>
            <li>Outils de configuration</li>
            <li>Services Red Hat</li>
          </ul>
          <p>Red Hat Linux :</p>
          <ul>
            <li>Distribution Linux</li>
            <li>Open-source</li>
            <li>Environnements professionnels</li>
          </ul>
          <p>Optimisation des Performances :</p>
          <ul>
            <li>Ajustement des paramètres</li>
            <li>Amélioration de l'efficacité</li>
            <li>Réactivité du système</li>
          </ul>
        `,
      },
      {
        title: "Sécurité systèmes et réseaux",
        duration: "28h",
        description: `
          <p>Gestion des Vulnérabilités :</p>
          <ul>
            <li>Compréhension, gestion des vulnérabilités</li>
            <li>Suivi et Reporting</li>
          </ul>
        `,
      },
      {
        title: "Collecte et analyse des logs",
        duration: "14h",
        description: `
          <p>Analyse de Journaux :</p>
          <ul>
            <li>Enregistrements d'activités système</li>
            <li>Diagnostiquer les erreurs</li>
            <li>Sécurité et utilisation du système</li>
          </ul>
          <p>Détection d'Incidents :</p>
          <ul>
            <li>Activités inhabituelles</li>
            <li>Surveillance continue</li>
            <li>Réaction aux signaux d'alarme</li>
          </ul>
          <p>Gestion de la Sécurité :</p>
          <ul>
            <li>Protocoles de sécurité</li>
            <li>Politiques de sécurité</li>
            <li>Prévention des vulnérabilités</li>
          </ul>
        `,
      },
      {
        title: "Détection d'intrusion et SOC",
        duration: "28h",
        description: `
          <p>Gestion des Alertes de Sécurité :</p>
          <ul>
            <li>Réception d'alertes de sécurité</li>
            <li>Analyse des avertissements</li>
            <li>Réponse aux menaces potentielles</li>
          </ul>
          <p>SOC (Security Operations Center) :</p>
          <ul>
            <li>Salle de contrôle de sécurité informatique</li>
            <li>Surveillance continue des activités</li>
            <li>Coordination des réponses aux incidents</li>
          </ul>
          <p>Analyse de Vulnérabilités :</p>
          <ul>
            <li>Identification des failles de sécurité</li>
            <li>Évaluation des risques</li>
            <li>Mise en place de correctifs</li>
          </ul>
        `,
      },
      {
        title: "Sécurisation systèmes Linux",
        duration: "21h",
        description: `
          <p>Mesures de Sécurité :</p>
          <ul>
            <li>Configuration de pare-feux</li>
            <li>Mise à jour régulière du système</li>
            <li>Formation des utilisateurs</li>
          </ul>
          <p>Gestion des Accès :</p>
          <ul>
            <li>Attribution des autorisations</li>
            <li>Contrôle des accès</li>
            <li>Suivi des modifications d'accès</li>
          </ul>
        `,
      },
      {
        title: "Virtualisation",
        duration: "35h",
        description: `
          <p>VMware vSphere :</p>
          <ul>
            <li>Plateforme de virtualisation</li>
            <li>Gestion centralisée des ressources</li>
            <li>Haute disponibilité et migration des machines virtuelles</li>
          </ul>
          <p>Machines Virtuelles :</p>
          <ul>
            <li>Environnements autonomes</li>
            <li>Consolidation des systèmes d'exploitation et applications</li>
            <li>Économie de ressources et environnements de test</li>
          </ul>
          <p>Hyperviseur :</p>
          <ul>
            <li>Logiciel de virtualisation</li>
            <li>Création et gestion des machines virtuelles</li>
            <li>Allocation de ressources physiques aux machines virtuelles</li>
          </ul>
        `,
      },
      {
        title: "Conteneurs virtuels d'application",
        duration: "21h",
        description: `
          <p>Docker :</p>
          <ul>
            <li>Conteneurisation</li>
            <li>Gestion de conteneurs</li>
            <li>Isolation d'applications</li>
          </ul>
        `,
      },
      {
        title: "Automatisation de la gestion des serveurs",
        duration: "14h",
        description: `
          <p>Ansible :</p>
          <ul>
            <li>Automatisation de la configuration</li>
            <li>Fichiers de configuration et "playbooks"</li>
            <li>Minimisation des erreurs humaines</li>
          </ul>
          <p>Infrastructure as Code (IaC) :</p>
          <ul>
            <li>Gestion de l'infrastructure via du code</li>
            <li>Automatisation du déploiement</li>
            <li>Gestion agile et reproductible</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "devuniversity.svg",
    entreprise: "DevUniversity",
    jobTitle: "Consultant test",
    jobTag: "Développement, systèmes et réseaux",
    jobTimeDay: "",
    jobTimeHour: "400h",
    profileSought: `Toute personne démontrant un intérêt pour l'informatique`,
    goals: generateGoalsHTML([
      "Maîtriser les commandes de bases du système d’exploitation Linux.",
      "Gérer les bases de données avec Oracle SQL et les requêtes SQL.",
      "Créer des pages web dynamiques avec HTML 5 et JavaScript et manipuler le DOM.",
      "Programmer en Python et appliquer des principes de programmation orientée objet en Python pour développer des applications.",
      "Utiliser Java pour la programmation orientée objet et mettre en œuvre des tests et la qualité logicielle dans des projets Java.",
      "Automatiser des tests et pratiquer le Test Driven Development en Java pour améliorer la qualité des logiciels.",
      "Créer des API JavaScript avec HTML 5 et déployer des services web.",
      "Appliquer des méthodes agiles de gestion de projet.",
      "Comprendre la méthodologie DevOps et utiliser Jenkins pour l'intégration continue, ainsi que SoapUI pour les tests de services web REST et SOAP.",
    ]),
    prerequisites: "Aucun",
    partners: ["Sogeti.svg", "groupe-open.png"],
    programmes: [
      {
        title: "Fondamentaux",
        duration: "",
        description: `
          <p>Réseaux :</p>
          <ul>
            <li>Architecture des réseaux</li>
            <li>Protocoles de communication</li>
            <li>Adressage IP</li>
            <li>Configuration des réseaux</li>
          </ul>
          <p>Systèmes d'Exploitation :</p>
          <ul>
            <li>Principes de fonctionnement</li>
            <li>Gestion des ressources, mémoire, processus</li>
          </ul>
          <p>Base de Données :</p>
          <ul>
            <li>Utilisation et gestion</li>
          </ul>
        `,
      },
      {
        title: "Linux, initiation",
        duration: "",
        description: `
          <p>Système d'Exploitation :</p>
          <ul>
            <li>Principes de fonctionnement</li>
            <li>Gestion des ressources, mémoire, processus</li>
          </ul>
          <p>Commandes de Base de Linux :</p>
          <ul>
            <li>mkdir, touch, cp, mv, rm.</li>
            <li>cat, head, tail, less, grep</li>
            <li>chmod, chown, passwd</li>
            <li>Gestion des processus : ps, top, kill.</li>
            <li>Réseau et connectivité : ping, ifconfig, netstat</li>
          </ul>
        `,
      },
      {
        title: "Bases de données",
        duration: "",
        description: `
          <p>Oracle SQL :</p>
          <ul>
            <li>Syntaxe de base d'Oracle SQL : SELECT, INSERT, UPDATE, DELETE.</li>
          </ul>
          <p>Requêtes SQL Avancées :</p>
          <ul>
            <li>Utilisation des jointures avancées (INNER JOIN, LEFT JOIN, RIGHT JOIN, FULL JOIN)</li>
            <li>Utilisation de fonctions d'agrégation (SUM, AVG, MAX, MIN, COUNT)</li>
            <li>Utilisation des clauses CASE et GROUP BY</li>
          </ul>
          <p>Gestion de Données</p>
        `,
      },
      {
        title: "Développement web, initiation",
        duration: "",
        description: `
          <p>HTML5 (HyperText Markup Language) :</p>
          <ul>
            <li>Éléments sémantiques: <header>, <nav>, <section>, <article>, <footer>.</li>
            <li>Balises audio, vidéo, canvas pour intégrer du contenu multimédia.</li>
          </ul>
          <p>JavaScript pour HTML Dynamique :</p>
          <ul>
            <li>Clics, soumissions de formulaires, survols, etc.</li>
            <li>AJAX</li>
          </ul>
          <p>DOM (Document Object Model) :</p>
          <ul>
            <li>Javascript</li>
          </ul>
        `,
      },
      {
        title: "Programmation orientée objet",
        duration: "",
        description: `
          <p>Python :</p>
          <ul>
            <li>Variables, types de données, opérateurs, contrôle de flux.</li>
            <li>Listes, les tuples, les dictionnaires et les ensembles.</li>
          </ul>
          <p>Programmation Objet en Python :</p>
          <ul>
            <li>Classes et objets.</li>
            <li>Entités et concepts.</li>
            <li>Attributs et méthodes</li>
            <li>Encapsulation, héritage et polymorphisme en Python.</li>
            <li>Interfaces et Abstractions</li>
          </ul>
        `,
      },
      {
        title: "Java et qualité logicielle",
        duration: "",
        description: `
          <p>Java et programmation orientée objet :</p>
          <ul>
            <li>Variables, types de données, opérateurs, contrôle de flux.</li>
            <li>Structures de contrôle et boucles en Java.</li>
            <li>Classes, objets, packages et exceptions en Java.</li>
            <li>Attributs et méthodes.</li>
            <li>Héritage, polymorphisme et encapsulation en Java.</li>
            <li>Utilisation d'interfaces et de classes abstraites.</li>
          </ul>
          <p>Tests et Qualité en Java :</p>
          <ul>
            <li>JUnit pour écrire et exécuter des tests unitaires.</li>
            <li>Tests d'intégration et tests fonctionnels.</li>
            <li>Bonnes pratiques, qualité du code, lisibilité, maintenabilité et réutilisabilité en Java.</li>
          </ul>
        `,
      },
      {
        title: "Tests et automatisation",
        duration: "",
        description: `
          <p>Automatisation :</p>
          <ul>
            <li>Avantages de l'automatisation dans le processus de développement.</li>
            <li>Selenium, Jira, Jenkins</li>
          </ul>
          <p>Test Driven Development (TDD) en Java :</p>
          <ul>
            <li>Écrire du test, échec du test, écriture du code pour réussir le test, refactorisation.</li>
            <li>Utilisation de frameworks tels que JUnit pour mettre en pratique le TDD en Java.</li>
            <li>Rédaction de tests unitaires avant de coder une fonctionnalité pour garantir la qualité du code.</li>
          </ul>
        `,
      },
      {
        title: "Développement web avancé",
        duration: "",
        description: `
          <p>API JavaScript avec HTML5 :</p>
          <ul>
            <li>Rôle des APIs dans le développement web.</li>
            <li>Utilisation des APIs JavaScript intégrées à HTML5 pour accéder à diverses fonctionnalités du navigateur.</li>
            <li>Exemple d'APIs HTML5 telles que Geolocation API, Canvas API, Web Audio API, Web Storage API, etc.</li>
            <li>Intégration et utilisation d'APIs tierces pour enrichir les fonctionnalités de l'application web.</li>
          </ul>
          <p>Création et Déploiement de Services Web :</p>
          <ul>
            <li>Node.js, Flask, ou Spring Boot.</li>
            <li>Requêtes HTTP, Méthodes HTTP (GET, POST, PUT, DELETE).</li>
            <li>Déploiement de services web sur des plateformes cloud ou serveurs.</li>
          </ul>
        `,
      },
      {
        title: "Méthodes agiles et gestion de projet",
        duration: "",
        description: `
          <p>Méthodes Agiles de Gestion de Projet :</p>
          <ul>
            <li>Scrum, Kanban, XP (eXtreme Programming), etc.</li>
            <li>Planifier, exécuter et livrer des projets de manière itérative et collaborative.</li>
          </ul>
          <p>Méthode SCRUM :</p>
          <ul>
            <li>Rôles (Product Owner, Scrum Master, Équipe de développement) et artefacts (Product Backlog, Sprint Backlog, Increment SCRUM.</li>
            <li>Organisation des cérémonies SCRUM (Sprint Planning, Daily Standup, Sprint Review, Sprint Retrospective)</li>
          </ul>
          <p>Jira Software :</p>
          <ul>
            <li>Création et gestion de backlogs de produits et de sprints dans Jira.</li>
            <li>Suivi des tâches, des progrès et des rapports de sprint à l'aide de Jira Software.</li>
          </ul>
          <p>Jira Administration :</p>
          <ul>
            <li>Personnalisation de Jira en fonction des besoins de l'équipe et du projet.</li>
            <li>Gestion des utilisateurs, des groupes et des permissions dans Jira.</li>
          </ul>
        `,
      },
      {
        title: "DevOps et tests d'intégration continue",
        duration: "",
        description: `
          <p>Méthodologie DevOps :</p>
          <ul>
            <li>Processus de développement (Dev) et d'opérations (Ops) pour accélérer le cycle de vie de développement et améliorer la qualité du logiciel.</li>
            <li>Adoption des automatisations, de la collaboration et de l'approche orientée service de DevOps.</li>
          </ul>
          <p>Jenkins :</p>
          <ul>
            <li>Intégration continue et déploiement continu.</li>
            <li>Configuration de pipelines d'intégration continue pour automatiser les tests, la compilation et le déploiement.</li>
            <li>Utilisation des plugins Jenkins pour étendre les fonctionnalités et personnaliser les pipelines.</li>
          </ul>
          <p>SoapUI : Tests de Services Web REST et SOAP :</p>
          <ul>
            <li>Utilisation de SoapUI pour automatiser les tests des services web RESTful et SOAP.</li>
            <li>Création de scénarios de tests, de requêtes, d'assertions et de validations dans SoapUI.</li>
          </ul>
          <p>Certification ISTQB Foundation :</p>
          <ul>
            <li>Compréhension des concepts fondamentaux du test logiciel, des types de tests et des bonnes pratiques.</li>
          </ul>
          <p>Référentiel de Tests avec HP ALM 12 :</p>
          <ul>
            <li>Utilisation de HP ALM 12 (Application Lifecycle Management) pour la gestion du cycle de vie des tests.</li>
            <li>Création et gestion d'un référentiel de tests pour stocker, organiser et exécuter les cas de test.</li>
            <li>Suivi des défauts, des rapports de tests et de l'état d'avancement du projet avec HP ALM.</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "cyber.svg",
    entreprise: "CyberUniversity",
    jobTitle: "Analyste cybersécurité",
    jobTag: "Cybersécurité",
    jobTimeDay: "",
    jobTimeHour: "400h",
    profileSought: `Bac + 3 en systèmes et réseaux`,
    goals: generateGoalsHTML([
      "Consolider ou acquérir les connaissances nécessaires à la compréhension de la cybersécurité en environnement IT et industriel.",
      "Connaître les contre-mesures d’une vulnérabilité et appliquer les principes de sécurité.",
      "Connaître et comprendre les principaux outils et méthodes d’attaques.",
      "Apprendre à détecter une attaque, connaître les outils d’investigation et appréhender l’activité d’un SOC.",
      "Se préparer à gérer une cybercrise et apprendre à éprouver son processus de gestion de cybercrise.",
      "Se familiariser avec les missions et savoir-faire d’un Analyste SOC, l’expert du renseignement cyber.",
      "Installer et configurer un SIEM.",
      "S’entraîner à créer et lancer des attaques.",
    ]),

    prerequisites: "Bac + 2, 1 an d'expérience en IT",
    partners: ["squad.png", "cybermaker.webp", "nda.png"],
    programmes: [
      {
        title: "Fondamentaux des systèmes et réseaux",
        duration: "47h",
        description: `
          <p>Système Linux :</p>
          <ul>
            <li>Configuration sécurisée</li>
            <li>Surveillance des journaux d'activité</li>
            <li>Politiques de sécurité</li>
          </ul>
          <p>Système Windows :</p>
          <ul>
            <li>Stratégies de groupe</li>
            <li>Surveillance des journaux d'événements</li>
            <li>Pare-feux et antivirus</li>
          </ul>
        `,
      },
      {
        title: "Fondamentaux de la cybersécurité et du SOC",
        duration: "21h",
        description: `
          <p>Guide Juridique :</p>
          <ul>
            <li>Lois et réglementations</li>
            <li>Normes de conformité (par exemple, le RGPD)</li>
            <li>Meilleures pratiques en matière de sécurité</li>
          </ul>
          <p>Organisation d'un SOC (Security Operations Center - Centre de Surveillance de la Sécurité) :</p>
          <ul>
            <li>Rôles et responsabilités des analystes de sécurité</li>
            <li>Outils de surveillance</li>
            <li>Procédures d'alerte et de réponse aux incidents</li>
          </ul>
        `,
      },
      {
        title: "Sécurité et Réseaux",
        duration: "27h",
        description: `
          <p>Sécurité du Réseau :</p>
          <ul>
            <li>Pare-feux</li>
            <li>Systèmes de détection d'intrusions</li>
            <li>Chiffrement des données</li>
          </ul>
          <p>Zero Trust Network (Réseau de Confiance Zéro) :</p>
          <ul>
            <li>Vérification de l'identité</li>
            <li>Validation continue</li>
            <li>Principe de moindre privilège</li>
          </ul>
        `,
      },
      {
        title: "Cryptographie et durcissement des systèmes",
        duration: "32h",
        description: `
          <p>VPN (Virtual Private Network - Réseau Privé Virtuel) :</p>
          <ul>
            <li>Tunnel sécurisé et chiffré</li>
            <li>Confidentialité des communications</li>
            <li>Utilisation dans des réseaux distants ou publics</li>
          </ul>
          <p>Cryptographie et IGC (Infrastructure à Clé Publique) :</p>
          <ul>
            <li>Chiffrement des données</li>
            <li>Authentification et signature numérique</li>
            <li>Sécurité des communications en ligne</li>
          </ul>
          <p>Durcissement des Systèmes :</p>
          <ul>
            <li>Réduction de la surface d'attaque</li>
            <li>Désactivation des fonctionnalités non nécessaires</li>
            <li>Surveillance des journaux d'activité</li>
          </ul>
        `,
      },
      {
        title: "SIEM / SPLUNK",
        duration: "27h",
        description: `
          <p>SPLUNK :</p>
          <ul>
            <li>Collecte et indexation des données de journal</li>
            <li>Analyse des journaux d'activité</li>
            <li>Génération d'alertes et de rapports</li>
          </ul>
          <p>Commandes et Rapports :</p>
          <ul>
            <li>Actions de suivi des activités</li>
            <li>Génération d'alertes</li>
            <li>Création de rapports pour la gestion de la sécurité</li>
          </ul>
        `,
      },
      {
        title: "SPLUNK Avancé",
        duration: "27h",
        description: `
          <p>Commandes avancées</p>
          <p>Rapport et visualisation avancée</p>
        `,
      },
      {
        title: "Ethical Hacking",
        duration: "32h",
        description: `
          <p>Tests d'Intrusion :</p>
          <ul>
            <li>Évaluation des vulnérabilités</li>
            <li>Méthodes d'attaque</li>
            <li>Recommandations pour la sécurité</li>
          </ul>
          <p>Techniques de Hacking et Rapports :</p>
          <ul>
            <li>Méthodes d'attaque et exploitation</li>
            <li>Vulnérabilités identifiées</li>
            <li>Rapports détaillés sur les tests d'intrusion</li>
          </ul>
        `,
      },
      {
        title: "APT et Mitre ATT&CK",
        duration: "22h",
        description: `
          <p>APT (Advanced Persistent Threat) :</p>
          <ul>
            <li>Attaques sophistiquées et persistantes</li>
            <li>Infiltration discrète</li>
            <li>Vol de données sensibles</li>
          </ul>
          <p>Mitre ATT&CK :</p>
          <ul>
            <li>Techniques et tactiques d'attaques</li>
            <li>Indicateurs de compromission</li>
            <li>Renforcement de la défense contre les menaces</li>
          </ul>
        `,
      },
      {
        title: "Détection d'intrusion",
        duration: "43h",
        description: `
          <p>Règles :</p>
          <ul>
            <li>Détection d'activités suspectes</li>
            <li>Identification d'indicateurs de menace</li>
            <li>Alertes de sécurité</li>
          </ul>
          <p>Analyses :</p>
          <ul>
            <li>Examen approfondi des données de journal</li>
            <li>Détection de schémas suspects</li>
            <li>Identification d'indicateurs d'intrusion potentiels</li>
          </ul>
          <p>Cyber Threat Intelligence :</p>
          <ul>
            <li>Collecte d'informations sur les menaces</li>
            <li>Analyse des tactiques des cybercriminels</li>
            <li>Renforcement de la sécurité et de la réponse aux incidents</li>
          </ul>
        `,
      },
      {
        title: "Forensique et réponse aux incidents",
        duration: "32h",
        description: `
          <p>Réponse aux Incidents :</p>
          <ul>
            <li>Identification et gestion des incidents</li>
            <li>Collecte de preuves</li>
            <li>Restauration des systèmes et communication avec les parties prenantes</li>
          </ul>
          <p>Gestion de Cybercrise :</p>
          <ul>
            <li>Mobilisation d'une équipe de réponse d'urgence</li>
            <li>Coordination des activités</li>
            <li>Communication et plans de continuité des activités</li>
          </ul>
          <p>Computer Forensics (Forensique Informatique) :</p>
          <ul>
            <li>Collecte et préservation des preuves numériques</li>
            <li>Analyse des données numériques</li>
            <li>Utilisation en enquêtes criminelles ou en incidents de sécurité</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "cyber.svg",
    entreprise: "CyberUniversity",
    jobTitle: "Administrateur cybersécurité",
    jobTag: "Cybersécurité",
    jobTimeDay: "",
    jobTimeHour: "400h",
    profileSought: `Bac + 2, 1 an d'expérience en IT`,
    goals: generateGoalsHTML([
      "Acquérir les fondamentaux de la cybersécurité, notamment en comprenant les réseaux, les systèmes d'exploitation et les bases de données.",
      "Administrer les systèmes Linux et Windows et les réseaux LAN & WAN.",
      "Gérer des environnements virtuels et cloud avec VMWare, Proxmox et AWS.  ",
      "Gérer les parcs informatiques en appliquant des méthodes ITIL, en utilisant des outils tels que ServiceNow, MDT et SolarWinds.",
      "Assurer la sécurité des systèmes d'information des réseaux et du cloud.",
      "Automatiser des tâches de sécurité et d'administration en utilisant des outils tels que Powershell et Ansible.",
      "Concevoir, mettre en place et gérer des infrastructures informatiques.",
      "Assurer la sécurité en effectuant des pentest et en réalisant des audits et des analyses de réseaux.",
      "Évaluer et améliorer la sécurité des systèmes d'information grâce à des techniques avancées de détection et de réponse aux incidents.",
    ]),

    prerequisites: "Systèmes d'exploitation Linux et Windows et Réseaux",
    partners: ["squad.png", "cybermaker.webp", "nda.png"],
    programmes: [
      {
        title: "Fondamentaux",
        duration: "30h",
        description: `
          <p>Réseaux :</p>
          <ul>
            <li>Architecture des réseaux</li>
            <li>Protocoles de communication</li>
            <li>Adressage IP</li>
            <li>Configuration des réseaux</li>
          </ul>
          <p>Systèmes d'Exploitation :</p>
          <ul>
            <li>Principes de fonctionnement</li>
            <li>Gestion des ressources, mémoire, processus</li>
          </ul>
          <p>Base de Données :</p>
          <ul>
            <li>Utilisation et gestion</li>
          </ul>
        `,
      },
      {
        title: "Administration des systèmes et des réseaux",
        duration: "60h",
        description: `
          <p>Systèmes Windows :</p>
          <ul>
            <li>Powershell</li>
            <li>Active Directory</li>
            <li>Microsoft System Center Configuration Manager (SCCM)</li>
          </ul>
          <p>Bash :</p>
          <ul>
            <li>Ansible</li>
            <li>Docker</li>
          </ul>
          <p>Réseaux LAN et WAN :</p>
          <ul>
            <li>Wireshark</li>
            <li>Cisco Packet Tracer</li>
            <li>Nagios</li>
          </ul>
        `,
      },
      {
        title: "Virtualisation et cloud",
        duration: "80h",
        description: `
          <p>VMware :</p>
          <ul>
            <li>VMware vSphere</li>
            <li>VMware Workstation</li>
            <li>VMware vRealize Suite</li>
          </ul>
          <p>Proxmox :</p>
          <ul>
            <li>Proxmox Virtual Environment (Proxmox VE)</li>
            <li>Proxmox Backup Server</li>
            <li>Proxmox Mail Gateway</li>
          </ul>
          <p>Cloud AWS (Amazon Web Services) :</p>
          <ul>
            <li>Amazon EC2 (Elastic Compute Cloud)</li>
            <li>Amazon S3 (Simple Storage Service)</li>
            <li>Amazon RDS (Relational Database Service)</li>
          </ul>
        `,
      },
      {
        title: "Gestion des parcs informatiques",
        duration: "60h",
        description: `
          <p>Méthode ITIL (Information Technology Infrastructure Library) :</p>
          <ul>
            <li>ServiceNow</li>
            <li>MDT (Microsoft Deployment Toolkit)</li>
          </ul>
          <p>ServiceNow :</p>
          <ul>
            <li>Incident Management</li>
            <li>Change Management</li>
          </ul>
          <p>MDT (Microsoft Deployment Toolkit) :</p>
          <ul>
            <li>Image Deployment</li>
            <li>Task Sequences</li>
          </ul>
          <p>SolarWinds :</p>
          <ul>
            <li>SolarWinds Network Performance Monitor (NPM)</li>
            <li>SolarWinds Server & Application Monitor (SAM)</li>
          </ul>
        `,
      },
      {
        title: "Sécurité des systèmes d'information",
        duration: "70h",
        description: `
          <p>Sécurité des systèmes Windows et Linux :</p>
          <ul>
            <li>Antivirus et antimalware</li>
            <li>Gestion des correctifs (patch management)</li>
          </ul>
          <p>Kali Linux :</p>
          <ul>
            <li>Outils de test de pénétration (Penetration Testing Tools)</li>
            <li>Audits de sécurité (Security Auditing)</li>
          </ul>
          <p>Sécurité dans le cloud :</p>
          <ul>
            <li>Gestion des identités et des accès (Identity and Access Management, IAM)</li>
            <li>Pare-feu de nouvelle génération (Next-Generation Firewalls)</li>
          </ul>
        `,
      },
      {
        title: "Script et automatisation",
        duration: "50h",
        description: `
          <p>PowerShell :</p>
          <ul>
            <li>Interpréteur de commandes</li>
            <li>Langage de script</li>
            <li>Gestion de la configuration</li>
          </ul>
          <p>Automatisation avec Python :</p>
          <ul>
            <li>Ansible</li>
            <li>Déploiement d'une application et de ses configurations via des playbooks</li>
            <li>Déploiement des composants de l'application</li>
            <li>Création et gestion des tests via Molecule</li>
            <li>Création d'un coffre-fort sécurisant les données sensibles</li>
          </ul>
        `,
      },
      {
        title:
          "Conception, mise en place et gestion de l'infrastructure informatique",
        duration: "50h",
        description: `
          <p>Sandbox Cloud :</p>
          <ul>
            <li>AWS (Amazon Web Services) Sandbox, Azure Sandbox, Google Cloud Playground</li>
            <li>Isolation, tests sans risque, clones d'environnements</li>
          </ul>
          <p>Architecture Hybride :</p>
          <ul>
            <li>AWS Outposts, Azure Arc, VMware Cloud on AWS</li>
            <li>Combinaison de ressources cloud et locales</li>
          </ul>
          <p>Conception et mise en place de l’infrastructure :</p>
          <ul>
            <li>Planification de l'infrastructure</li>
            <li>Choix de l'architecture matérielle et logicielle</li>
            <li>Intégration des services</li>
          </ul>
          <p>Sécurité et gestion des accès :</p>
          <ul>
            <li>Contrôle d'accès</li>
            <li>Gestion des identités</li>
          </ul>
        `,
      },
      {
        title: "Spécialisation sécurité",
        duration: "40h",
        description: `
          <p>Pentest (Test d'intrusion) :</p>
          <ul>
            <li>Évaluation de la sécurité</li>
            <li>Exploitation des vulnérabilités</li>
          </ul>
          <p>Audit et analyse des réseaux :</p>
          <ul>
            <li>Évaluation de la performance réseau</li>
            <li>Analyse de trafic</li>
          </ul>
          <p>Kali Linux :</p>
          <ul>
            <li>Distribution de test d'intrusion</li>
            <li>Outils de hacking éthique</li>
          </ul>
        `,
      },
      {
        title: "Projet",
        duration: "10h",
        description: "",
      },
    ],
  },
  {
    id: uuidv4(),
    logo: "cyber.svg",
    entreprise: "CyberUniversity",
    jobTitle: "Consultant cybersécurité",
    jobTag: "Cybersécurité",
    jobTimeDay: "",
    jobTimeHour: "400h",
    profileSought: `Bac +3/4 en systèmes et réseaux ou informatique`,
    goals: generateGoalsHTML([
      "Appliquer les pratiques de sécurité sur les systèmes et les réseaux.",
      "Comprendre les défis de la sécurité dans le cloud et apprendre à sécuriser les environnements sur les plateformes Azure, AWS et GCP.",
      "Pratiquer l'ethical hacking.",
      "Comprendre et maîtriser le rôle du consultant.",
      "Implémenter des normes liées à la sécurité des systèmes d'information, introduire la gouvernance, le risque et la conformité (GRC) et se préparer à la certification ISO 27001 Lead Implementer.",
      "Conduire des analyses de risque et se préparer aux certifications ISO 27005 Risk Manager et EBIOS RM.",
      "Gérer efficacement les incidents de cybersécurité, répondre aux cyber crises et se préparer au passage de la certification ISO 27035 .",
      "Appréhender le RGPD, comprendre le rôle du Data Product Officer et effectuer une veille juridique pour rester conforme aux réglementations.",
    ]),
    prerequisites:
      "Connaissances en réseaux, systèmes et informatique en général",
    partners: ["squad.png", "cybermaker.webp", "nda.png"],
    programmes: [
      {
        title: "Fondamentaux systèmes et réseaux",
        duration: "36h",
        description: `
          <p>Réseaux :</p>
          <ul>
            <li>Topologie réseau</li>
            <li>Protocoles de communication</li>
          </ul>
          <p>Systèmes d’exploitation :</p>
          <ul>
            <li>Gestion de la mémoire</li>
            <li>Gestion des fichiers</li>
          </ul>
        `,
      },
      {
        title: "Les bases de la sécurité systèmes et réseaux",
        duration: "38h",
        description: `
          <p>Introduction à la cybersécurité :</p>
          <ul>
            <li>Menaces en ligne</li>
            <li>Sécurité de l'information</li>
          </ul>
          <p>Sécurité des réseaux :</p>
          <ul>
            <li>Pare-feu réseau</li>
            <li>Cryptographie réseau</li>
          </ul>
          <p>Sécurité des systèmes et de l’Active Directory :</p>
          <ul>
            <li>Politiques de sécurité</li>
            <li>Gestion des comptes d'utilisateur</li>
          </ul>
        `,
      },
      {
        title: "Le Cloud et sa sécurité",
        duration: "21h",
        description: `
          <p>Sécurité sur Azure :</p>
          <ul>
            <li>Azure Security Center</li>
            <li>Gestion des identités Azure</li>
          </ul>
          <p>Sécurité sur AWS :</p>
          <ul>
            <li>AWS Identity and Access Management (IAM)</li>
            <li>Sécurité des données sur AWS</li>
          </ul>
          <p>Sécurité sur GCP :</p>
          <ul>
            <li>Gestion des accès sur Google Cloud</li>
            <li>Sécurité des données sur GCP</li>
          </ul>
        `,
      },
      {
        title: "Ethical Hacking",
        duration: "28h",
        description: `
          <p>Audit de pentest :</p>
          <ul>
            <li>Évaluation des vulnérabilités</li>
            <li>Exploitation des failles</li>
          </ul>
          <p>Hacking Active Directory :</p>
          <ul>
            <li>Attaques sur les annuaires LDAP</li>
            <li>Exploitation des vulnérabilités AD</li>
          </ul>
          <p>Hacking avancé :</p>
          <ul>
            <li>Techniques d'intrusion sophistiquées</li>
            <li>Évasion de détection</li>
          </ul>
        `,
      },
      {
        title: "Soft skills",
        duration: "17h",
        description: `
          <p>Le métier de consultant :</p>
          <ul>
            <li>Conseil en stratégie</li>
            <li>Analyse des besoins du client</li>
          </ul>
          <p>S’adapter aux besoins du client :</p>
          <ul>
            <li>Personnalisation des solutions</li>
            <li>Flexibilité dans l'approche</li>
          </ul>
          <p>Réussir une présentation efficace :</p>
          <ul>
            <li>Communication persuasive</li>
            <li>Préparation de diapositives</li>
          </ul>
        `,
      },
      {
        title: "Implémentation des normes liées à la SSI",
        duration: "42h",
        description: `
          <p>Introduction à la GRC (Gestion des Risques et de la Conformité) :</p>
          <ul>
            <li>Évaluation des risques</li>
            <li>Conformité réglementaire</li>
          </ul>
          <p>ISO 27001 Lead Implementer :</p>
          <ul>
            <li>Mise en œuvre de la norme ISO 27001</li>
            <li>Gestion du système de management de la sécurité de l'information (SMSI)</li>
          </ul>
        `,
      },
      {
        title: "Les analyses de risque",
        duration: "31h",
        description: `
          <p>ISO 27005 Risk Manager :</p>
          <ul>
            <li>Gestion des risques de sécurité de l'information</li>
            <li>Analyse des risques</li>
          </ul>
          <p>EBIOS RM :</p>
          <ul>
            <li>Méthodologie d'analyse des risques</li>
            <li>Gestion des risques</li>
          </ul>
        `,
      },
      {
        title: "Gestion des incidents et gestion de crise cyber",
        duration: "28h",
        description: `
          <p>Réponse à incident :</p>
          <ul>
            <li>Gestion des incidents de sécurité</li>
            <li>Récupération après une faille de sécurité</li>
          </ul>
          <p>ISO 27035 :</p>
          <ul>
            <li>Norme ISO pour la gestion des incidents de sécurité</li>
            <li>Analyse des incidents de sécurité</li>
          </ul>
          <p>PCA / PRA (Plan de Continuité d'Activité / Plan de Reprise d'Activité) :</p>
          <ul>
            <li>Préparation aux crises</li>
            <li>Récupération après une interruption</li>
          </ul>
        `,
      },
      {
        title: "Réglementation en cybersécurité",
        duration: "24h",
        description: `
          <p>RGPD (Règlement général sur la protection des données) :</p>
          <ul>
            <li>Protection des données personnelles</li>
            <li>Conformité à la législation sur la confidentialité</li>
          </ul>
          <p>Le métier de Data Product officer :</p>
          <ul>
            <li>Gestion des produits de données</li>
            <li>Stratégie de données</li>
          </ul>
          <p>Veille juridique en cybersécurité :</p>
          <ul>
            <li>Surveillance des développements juridiques</li>
            <li>Conformité légale en cybersécurité</li>
          </ul>
        `,
      },
      {
        title: "Projet",
        duration: "reste",
        description: "",
      },
    ],
  },
];
