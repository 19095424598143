const _orientate = (orientation: string): string => {
  switch (orientation) {
    case "bottom":
      return "0deg";
    case "top":
      return "180deg";
    case "left":
      return "90deg";
    case "right":
      return "270deg";
    default:
      return "0deg";
  }
};

const Arrow = ({
  color = "#737373",
  classes = "me-2",
  orientation = "bottom",
  height = "1em",
}: {
  color?: string;
  classes?: string;
  orientation?: string;
  height?: string;
}) => (
  <svg
    className={classes}
    height={height}
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `rotate(${_orientate(orientation)})` }}
  >
    <path
      d="M6.12018 9.05988C5.87894 9.47771 5.27586 9.47771 5.03463 9.05989L2.4849 4.64363C2.32318 4.36352 2.7153 4.09797 2.91537 4.3521C4.27186 6.07508 6.88294 6.07508 8.23943 4.3521C8.43951 4.09797 8.83162 4.36352 8.66991 4.64363L6.12018 9.05988Z"
      fill={color}
    />
    <rect x="5.00002" width="1" height="6" rx="0.5" fill={color} />
  </svg>
);

export default Arrow;
