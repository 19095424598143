import React, { useEffect, useRef, useState } from "react";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import DstButton from "../../components/DstButton/DstButton";
import Arrow from "../../components/DstIcon/Arrow";
import { useNavigate } from "react-router-dom";
import { jobspoei, JobPoei } from "./JobBoardPoeiMock";
import JobBlock from "../../components/JobBlock/JobBlock";
import ExternalLink from "../../components/DstIcon/ExternalLink";
import "./JobBoardPoei.scss";

const normalizeText = (text: string) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const getJobTitleWithoutAccents = (jobTitle: string) => {
  const normalizedTitle = normalizeText(jobTitle);
  const replacedTitle = normalizedTitle
    .replace(/\s*\/\s*/g, "-")
    .replace(/\s*-\s*/g, "-")
    .replace(/\s+/g, "-");
  return replacedTitle;
};

const JobBoardPoei = () => {
  const offreRefPoei = useRef<HTMLHeadingElement>(null);
  const navigate = useNavigate();
  const [selectedJob, setSelectedJob] = useState<JobPoei | null>(null);
  const [selectedJobIndex, setSelectedJobIndex] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTabSelect = (eventKey: string | null) => {
    setSelectedJob(null);
    setSelectedJobIndex(null);
  };

  const openJobDetails = (job: JobPoei, index: number) => {
    setSelectedJob(job);
    setSelectedJobIndex(index);

    if (isMobile) {
      navigate(
        `/poei/${job.id}/${getJobTitleWithoutAccents(
          job.entreprise
        )}/jobs/${getJobTitleWithoutAccents(job.jobTitle)}`,
        {
          state: { job: job },
        }
      );
    }

    const targetElement = document.getElementById("offre");
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const renderJobDetails = () => {
    if (!selectedJob) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="border p-3 beige-background">
          <div className="d-flex align-items-center">
            <img
              src={`/assets/images/entreprise/${selectedJob.logo}`}
              alt="Logo de l'entreprise"
              className="job-block-logo p-2"
            />
            <div className="ms-3">
              <h2 className="job-block-title mb-0">{selectedJob.jobTitle}</h2>
              <div className="gray-font">
                {selectedJob.jobTimeDay}
                {selectedJob.jobTimeDay ? " soit " : null}
                {selectedJob.jobTimeHour}
              </div>
            </div>
          </div>

          <div className="mt-4">
            Prérequis :{" "}
            <span className="gray-font"> {selectedJob.prerequisites}</span>
          </div>
          <div className="mt-4">
            <h3>Objectifs</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedJob.goals,
              }}
            />
          </div>
          <div className="mt-3">
            <h3>Profil recherché</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedJob.profileSought,
              }}
            />
          </div>

          {selectedJob.partners && (
            <div className="mt-3 ">
              <h3>Nos entreprises partenaires</h3>
              <div>
                {selectedJob.partners.map((partner, index) => (
                  <img
                    key={index}
                    src={`/assets/images/entreprise/${partner}`}
                    alt={`Logo du partenaire ${index}`}
                    className="me-4 mt-2 mh-50"
                  />
                ))}
              </div>
            </div>
          )}
          <div className="mt-5">
            <DstButton
              btnClass="mt-3 mt-md-0 me-2"
              value="Je postule"
              variant="violet"
              btnImage={<ExternalLink />}
              clickFunction={() => navigate("/rdv-poei")}
            />
            <DstButton
              btnClass="ms-2"
              value="Voir les détails de l’offre"
              color="#4628DD"
              variant="link"
              clickFunction={() =>
                navigate(
                  `/poei/${selectedJob.id}/${getJobTitleWithoutAccents(
                    selectedJob.entreprise
                  )}/jobs/${getJobTitleWithoutAccents(selectedJob.jobTitle)}`,
                  {
                    state: { job: selectedJob },
                  }
                )
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <DstNavbar buttonLink="/rdv-poei" />
      <div className="position-relative text-center">
        <img
          src="/assets/images/header-poei.png"
          alt=""
          className="img-fluid"
        />

        <Col
          sm={10}
          md={8}
          lg={6}
          xl={5}
          className="mx-auto header-career p-3 p-md-5"
        >
          <h1>Découvrez notre Portail Carrière</h1>
          <p className="l-light mt-3">
            Explorez les opportunités d'alternance chez DataScientest et donnez
            un élan à votre future carrière !
          </p>
          <DstButton
            btnClass="m-2"
            variant="link"
            color="#4628DD"
            value="Découvrir toutes nos offres"
            clickFunction={() =>
              offreRefPoei.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
            btnImage={
              <Arrow color="#4628dd" classes="ms-2" orientation="bottom" />
            }
          />
        </Col>
      </div>
      <Container className="mb-3 mb-md-5 mt-6">
        <h2 className="anchor" ref={offreRefPoei} id="offre">
          Dernières offres disponibles
        </h2>

        <Tabs
          defaultActiveKey="tous"
          id="tab-filter"
          className="mb-3"
          fill
          onSelect={handleTabSelect}
        >
          {[
            { key: "tous", title: "Toutes les offres", filterBy: "" },
            {
              key: "datascientest",
              title: "DataScientest",
              filterBy: "DataScientest",
            },
            {
              key: "devuniversity",
              title: "DevUniversity",
              filterBy: "DevUniversity",
            },
            {
              key: "cyberuniversity",
              title: "CyberUniversity",
              filterBy: "CyberUniversity",
            },
          ].map((tab) => (
            <Tab eventKey={tab.key} title={tab.title} key={tab.key}>
              <Row>
                <Col lg>
                  <div className="job-page">
                    {jobspoei
                      .filter(
                        (job) =>
                          tab.filterBy === "" || job.entreprise === tab.filterBy
                      )
                      .map((job, index) => (
                        <JobBlock
                          key={index}
                          logo={job.logo}
                          jobTitle={job.jobTitle}
                          jobTag={job.jobTag}
                          jobTimeHour={job.jobTimeHour}
                          onClick={() => openJobDetails(job, index)}
                          isSelected={selectedJobIndex === index}
                        />
                      ))}
                    {jobspoei.filter(
                      (job) =>
                        tab.filterBy === "" || job.entreprise === tab.filterBy
                    ).length === 0 && (
                      <p>Aucune offre disponible pour cette entreprise.</p>
                    )}
                  </div>
                </Col>
                <Col lg className="job-details-column">
                  {!isMobile && selectedJob && renderJobDetails()}
                </Col>
              </Row>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </React.Fragment>
  );
};

export default JobBoardPoei;
