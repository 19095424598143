import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import "./DstButton.scss";

const DstButton = ({
  backgroundColor,
  btnClass = "s-bold",
  btnImage,
  btnSize = "auto",
  clickFunction,
  color,
  disabled,
  link,
  target = "_self",
  type = "button",
  value = "Click here",
  variant = "primary",
}: {
  backgroundColor?: string;
  btnClass?: string;
  btnImage?: JSX.Element;
  btnSize?: string;
  clickFunction?: Function;
  color?: string;
  disabled?: boolean;
  link?: string;
  target?: string;
  type?: "submit" | "button" | "reset" | undefined;
  value?: string;
  variant?: string;
}) =>
  link ? (
    link.startsWith("http") ? (
      <a
        className={`s-bold ${btnClass}`}
        style={{
          backgroundColor: backgroundColor,
          color: color,
          width: btnSize,
        }}
        href={link}
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : ""}
      >
        {value}
      </a>
    ) : (
      <Link
        className={`s-bold ${btnClass}`}
        style={{
          backgroundColor: backgroundColor,
          color: color,
          width: btnSize,
        }}
        target={target}
        to={link}
      >
        {value}
      </Link>
    )
  ) : (
    <Button
      variant={variant}
      className={`s-bold ${btnClass}`}
      style={{
        backgroundColor: backgroundColor,
        color: color,
        width: btnSize,
      }}
      type={type}
      onClick={() => (clickFunction ? clickFunction() : null)}
      disabled={disabled}
    >
      <React.Fragment>
        {value}
        {btnImage}
      </React.Fragment>
    </Button>
  );

export default DstButton;
