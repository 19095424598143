import React from "react";
import DstButton from "../DstButton/DstButton";
import Format from "../DstIcon/Format";
import PinLocation from "../DstIcon/PinLocation";
import Clock from "../DstIcon/Clock"; // Ajoutez l'import pour l'icône de l'horloge
import "./JobBlock.scss";

interface JobBlockProps {
  logo?: string;
  backgroundLogo?: string;
  jobTitle?: string;
  location?: string;
  jobTag?: string;
  jobType?: string;
  jobTimeHour?: string; // Ajoutez la propriété jobTimeHour
  jobLink?: string;
  highlightWords?: string[];
  onClick?: () => void;
  isSelected?: boolean;
}

const JobBlock = ({
  logo,
  backgroundLogo,
  jobTitle,
  location,
  jobTag,
  jobType,
  jobTimeHour,
  highlightWords = [],
  onClick,
  isSelected = false,
}: JobBlockProps) => {
  const className = isSelected ? "selected" : "";

  return (
    <div
      className={`beige-background p-3 mb-3 d-md-flex d-lg-block d-xl-flex justify-content-between align-items-center ${className}`}
      onClick={onClick}
    >
      <div className="d-flex align-items-center">
        <img
          src={`/assets/images/entreprise/${logo}`}
          alt="Logo de l'entreprise"
          className={`job-block-logo p-2 ${backgroundLogo}`}
        />
        <div className="ms-3 w-100">
          <h2 className="job-block-title">
            {highlightWords
              ? jobTitle?.split(" ").map((word, index) =>
                  highlightWords.includes(word) ? (
                    <span key={index} className="highlight">
                      {word}{" "}
                    </span>
                  ) : (
                    word + " "
                  )
                )
              : jobTitle}
          </h2>
          <div className="d-flex gray-font">
            {location && (
              <p className="job-block-location">
                <PinLocation color="gray" height="13px" classes="me-1" />
                {location}
              </p>
            )}

            {jobTag && (
              <React.Fragment>
                <p className="job-block-location">{jobTag}</p>
              </React.Fragment>
            )}
            {jobType && (
              <React.Fragment>
                <p className="job-block-separate mx-3">|</p>
                <p className="job-block-type">
                  <Format color="gray" height="13px" classes="me-1" />
                  {jobType}
                </p>
              </React.Fragment>
            )}

            {jobTimeHour && (
              <React.Fragment>
                <p className="job-block-separate mx-3">|</p>
                <p className="job-block-type">
                  <Clock color="gray" height="13px" classes="me-1" />{" "}
                  {jobTimeHour}
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className="text-end">
        <DstButton variant="link" color="#A428DD" value="Voir l'offre" />
      </div>
    </div>
  );
};

export default JobBlock;
