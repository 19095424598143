import React, { useEffect, useRef, useState } from "react";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import NavbarDst from "../../components/DstNavbar/DstNavbar";
import DstFooter from "../../components/DstFooter/DstFooter";
import JobBlock from "../../components/JobBlock/JobBlock";
import { Col, Container, Row } from "react-bootstrap";
import { jobs, Job } from "./JobBoardMock";
import DstButton from "../../components/DstButton/DstButton";
import { useNavigate } from "react-router-dom";

import Arrow from "../../components/DstIcon/Arrow";
import ExternalLink from "../../components/DstIcon/ExternalLink";
import "./JobBoard.scss";

const normalizeText = (text: string) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const getJobTitleWithoutAccents = (jobTitle: string) => {
  const normalizedTitle = normalizeText(jobTitle);
  const replacedTitle = normalizedTitle
    .replace(/\s*\/\s*/g, "-")
    .replace(/\s*-\s*/g, "-")
    .replace(/\s+/g, "-");
  return replacedTitle;
};

const JobBoard = () => {
  const navigate = useNavigate();
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [selectedJobIndex, setSelectedJobIndex] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const offreRef = useRef<HTMLHeadingElement>(null);
  const [showFullDescription] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openJobDetails = (job: Job, index: number) => {
    setSelectedJob(job);
    setSelectedJobIndex(index);

    if (isMobile) {
      navigate(
        `/${job.id}/companies/${getJobTitleWithoutAccents(
          job.entreprise
        )}/jobs/${getJobTitleWithoutAccents(job.jobTitle)}`,
        {
          state: { job: job },
        }
      );
    }

    const targetElement = document.getElementById("offre");
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <React.Fragment>
      <NavbarDst />
      <div className="position-relative text-center">
        <img src="/assets/images/header-ban.png" alt="" className="img-fluid" />

        <Col
          sm={10}
          md={8}
          lg={6}
          xl={5}
          className="mx-auto header-career p-3 p-md-5"
        >
          <h1>Découvrez notre Portail Carrière</h1>
          <p className="l-light mt-3">
            Explorez les opportunités d'alternance chez DataScientest et donnez
            un élan à votre future carrière !
          </p>
          <DstButton
            btnClass="m-2"
            variant="link"
            color="#4628DD"
            value="Découvrir toutes nos offres"
            clickFunction={() =>
              offreRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
            btnImage={
              <Arrow color="#4628dd" classes="ms-2" orientation="bottom" />
            }
          />
        </Col>
      </div>
      <Container className="mb-3 mb-md-5 mt-6">
        <h2 className="anchor" ref={offreRef} id="offre">
          Dernières offres disponibles
        </h2>
        <Row>
          <Col lg>
            <div className="job-page">
              {jobs.map((job, index) => (
                <JobBlock
                  key={index}
                  logo={job.logo}
                  backgroundLogo="white-background"
                  jobTitle={job.jobTitle}
                  location={job.location}
                  jobType={job.jobType}
                  highlightWords={job.highlightWords}
                  onClick={() => openJobDetails(job, index)}
                  isSelected={selectedJobIndex === index}
                />
              ))}
            </div>
          </Col>
          <Col lg>
            {!isMobile && selectedJob && (
              <React.Fragment>
                <div className="border p-3 beige-background">
                  <div className="d-flex align-items-center">
                    <img
                      src={`/assets/images/entreprise/${selectedJob.logo}`}
                      alt="Logo de l'entreprise"
                      className="job-block-logo bg-white p-2"
                    />
                    <div className="ms-3">
                      <h2 className="job-block-title mb-0">
                        {selectedJob.highlightWords
                          ? selectedJob.jobTitle
                              ?.split(" ")
                              .map((word, index) =>
                                selectedJob.highlightWords.includes(word) ? (
                                  <span key={index} className="highlight">
                                    {word}{" "}
                                  </span>
                                ) : (
                                  word + " "
                                )
                              )
                          : selectedJob.jobTitle}
                      </h2>
                      <div>
                        <span className="gray-font">chez </span>
                        {selectedJob.entreprise}
                      </div>

                      <div className="mt-3">Détails :</div>
                      <div className="d-flex justify-content-between gray-font">
                        <p className="job-block-location">
                          {selectedJob.location}
                        </p>
                        <p className="job-block-type mx-3">
                          {selectedJob.jobType}
                        </p>
                        <p className="job-block-type">
                          {selectedJob.jobDiplome}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3>Description du poste</h3>
                    <div>
                      {showFullDescription ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selectedJob.jobDescription,
                          }}
                        />
                      ) : (
                        <div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                selectedJob.jobDescription.length > 400
                                  ? selectedJob.jobDescription.slice(0, 400) +
                                    "..."
                                  : selectedJob.jobDescription,
                            }}
                          />
                          {selectedJob.jobDescription.length > 400 && (
                            <DstButton
                              value="Voir plus >"
                              variant="link"
                              btnClass="p-0 gray-font text-decoration-none"
                              clickFunction={() =>
                                navigate(
                                  `/${
                                    selectedJob.id
                                  }/companies/${getJobTitleWithoutAccents(
                                    selectedJob.entreprise
                                  )}/jobs/${getJobTitleWithoutAccents(
                                    selectedJob.jobTitle
                                  )}`,
                                  {
                                    state: { job: selectedJob },
                                  }
                                )
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-3">
                    <h3>Profil recherché</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedJob.profileSought,
                      }}
                    />
                  </div>
                  <DstButton
                    btnClass="mt-3 mt-md-0 me-2"
                    value="Je postule"
                    variant="violet"
                    btnImage={<ExternalLink />}
                    clickFunction={() => navigate("/rdv")}
                  />
                  <DstButton
                    btnClass="ms-2"
                    value="Voir les détails de l’offre"
                    color="#DBA02A"
                    variant="link"
                    clickFunction={() =>
                      navigate(
                        `/${
                          selectedJob.id
                        }/companies/${getJobTitleWithoutAccents(
                          selectedJob.entreprise
                        )}/jobs/${getJobTitleWithoutAccents(
                          selectedJob.jobTitle
                        )}`,
                        {
                          state: { job: selectedJob },
                        }
                      )
                    }
                  />
                </div>
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
      <PreFooterOmnes />
      <DstFooter />
    </React.Fragment>
  );
};

export default JobBoard;
