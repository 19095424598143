import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Job, jobs } from "../JobBoard/JobBoardMock";
import { Col, Container, Row } from "react-bootstrap";
import DstButton from "../../components/DstButton/DstButton";
import ExternalLink from "../../components/DstIcon/ExternalLink";
import PinLocation from "../../components/DstIcon/PinLocation";
import Format from "../../components/DstIcon/Format";
import Graduate from "../../components/DstIcon/Graduate";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import Footer from "../../components/DstFooter/DstFooter";
import ChevronRight from "../../components/DstIcon/ChevronRight";
import "./JobOffer.scss";

interface LocationState {
  job?: Job;
}

const JobOffer = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const location = useLocation();
  const [jobFound, setJobFound] = useState<boolean>(true);

  useEffect(() => {
    const stateJob = (location.state as LocationState)?.job;
    if (stateJob) {
      setSelectedJob(stateJob);
    } else {
      const matchingJob = jobs.find((job) => job.id === jobId);
      if (matchingJob) {
        setSelectedJob(matchingJob);
      } else {
        setJobFound(false);
      }
    }
  }, [jobId, location.state]);

  return (
    <React.Fragment>
      <DstNavbar />
      <div className="text-center background-offre"></div>
      <Container className="mb-5 mt-n4">
        {jobFound ? (
          <React.Fragment>
            <div
              className={`mb-3 d-md-flex justify-content-between align-items-center text-center`}
            >
              <div className="d-flex flex-column flex-md-row align-items-center">
                {selectedJob && (
                  <img
                    src={`/assets/images/entreprise/${selectedJob.logo}`}
                    alt="Logo de l'entreprise"
                    className="job-offer-logo border rounded white-background p-2"
                  />
                )}
                <div className="ms-sm-3">
                  {selectedJob && (
                    <h1>
                      {selectedJob.highlightWords
                        ? selectedJob.jobTitle?.split(" ").map((word, index) =>
                            selectedJob.highlightWords.includes(word) ? (
                              <span key={index} className="highlight">
                                {word}{" "}
                              </span>
                            ) : (
                              word + " "
                            )
                          )
                        : selectedJob.jobTitle}
                    </h1>
                  )}
                </div>
              </div>
              <DstButton
                btnClass="mt-3 mt-md-0"
                value="Je postule"
                variant="violet"
                btnImage={<ExternalLink />}
                clickFunction={() => navigate("/rdv")}
              />
            </div>
            {selectedJob && (
              <Row className="mt-3 mt-md-5">
                <Col md>
                  <PinLocation color="#DBA02A" />
                  <span className="gray-font">Lieu : </span>
                  <span>{selectedJob.location}</span>
                </Col>
                <Col md className="text-md-center">
                  <Format color="#DBA02A" />
                  <span className="gray-font">Durée du contrat : </span>
                  {selectedJob.jobType}
                </Col>
                <Col md className="text-md-end">
                  <Graduate color="#DBA02A" />
                  <span className="gray-font">Formation : </span>
                  {selectedJob.jobDiplome}
                </Col>
              </Row>
            )}

            {selectedJob && (
              <Row className="mt-4 mt-md-5">
                <Col lg={8}>
                  <h3>Description du poste</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedJob.jobDescription,
                    }}
                  />
                  <h3 className="mt-4">Profil recherché</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedJob.profileSought,
                    }}
                  />
                </Col>

                <Col lg={4}>
                  <div className="beige-background p-3 p-lg-4 border">
                    <h2>Nos partenariats</h2>
                    <p className="mt-4">
                      DataScientest est le leader français en formation en Data
                      Science. Nous proposons des formations adaptées aux
                      professionnels et aux particuliers, avec un suivi
                      pédagogique personnalisé. Notre objectif est d'aider nos
                      apprenants à acquérir de nouvelles compétences et à
                      trouver un emploi qui leur convient. En travaillant en
                      collaboration avec nos partenaires, nous adaptons
                      constamment nos programmes aux besoins du marché. Nous
                      sélectionnons soigneusement les opportunités
                      professionnelles affichées sur notre Portail Carrière pour
                      correspondre à nos programmes de masters. Chez
                      DataScientest, nous accompagnons activement les candidats
                      tout au long du processus de recrutement en offrant un
                      soutien personnalisé.
                    </p>
                    <DstButton
                      value="Découvrir nos formations"
                      btnClass="btn btn-yellow mt-3"
                      link="https://datascientest.com/formations-data"
                    />
                  </div>
                </Col>
              </Row>
            )}

            {selectedJob && (
              <Row className="justify-content-center mt-3">
                <DstButton
                  btnClass="m-2"
                  variant="violet"
                  btnImage={<ExternalLink />}
                  value="Je postule"
                  clickFunction={() => navigate("/rdv")}
                />
                <DstButton
                  btnClass="m-2"
                  variant="link"
                  color="#4628DD"
                  value="Voir les autres offres"
                  clickFunction={() => navigate("/")}
                  btnImage={<ChevronRight color="#4628dd" classes="ms-2" />}
                />
              </Row>
            )}
          </React.Fragment>
        ) : (
          <div className="text-center mt-5">
            <h2>Offre non disponible</h2>
            <p>
              L'offre demandée n'est pas disponible. Veuillez retourner à la
              page d'accueil pour consulter d'autres offres.
            </p>
            <DstButton
              value="Retour à l'accueil"
              clickFunction={() => navigate("/")}
            />
          </div>
        )}
      </Container>
      <PreFooterOmnes />
      <Footer />
    </React.Fragment>
  );
};

export default JobOffer;
