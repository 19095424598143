import React from "react";
import HubSpotMeetingIframe from "../../components/HubspotMeeting/HubspotMeeting";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import Footer from "../../components/DstFooter/DstFooter";
import { Container } from "react-bootstrap";

const Rdv = () => {
  return (
    <React.Fragment>
      <DstNavbar buttonLink="/rdv-poei" />
      <Container>
        <div className="text-center mt-4">
          <h1>Prise de rendez-vous POEI</h1>
        </div>
        <div className="mb-5">
          <HubSpotMeetingIframe source="https://meetings.hubspot.com/youssef-sbay/poei-lets-discuss-your-project?embed=true" />
        </div>
      </Container>
      <PreFooterOmnes />
      <Footer />
    </React.Fragment>
  );
};

export default Rdv;
