import React from "react";

const Graduate = ({ classes = "ms-2" }: { classes?: string }) => {
  return (
    <svg
      className={classes}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 10.25V14.75C13.5 15.1478 13.342 15.5294 13.0607 15.8107C12.7794 16.092 12.3978 16.25 12 16.25H3.75C3.35218 16.25 2.97064 16.092 2.68934 15.8107C2.40804 15.5294 2.25 15.1478 2.25 14.75V6.5C2.25 6.10218 2.40804 5.72064 2.68934 5.43934C2.97064 5.15804 3.35218 5 3.75 5H8.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 2.75H15.75V7.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 11L15.75 2.75"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Graduate;
