import React from "react";
import HubSpotMeetingIframe from "../../components/HubspotMeeting/HubspotMeeting";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import Footer from "../../components/DstFooter/DstFooter";
import { Container } from "react-bootstrap";

const Rdv = () => {
  return (
    <React.Fragment>
      <DstNavbar />
      <Container>
        <div className="text-center mt-4">
          <h1>Prise de rendez-vous</h1>
        </div>
        <div className="mb-5">
          <HubSpotMeetingIframe source="https://meetings.hubspot.com/datascientest/rendez-vous-alternance-datascientest?embed=true&parentHubspotUtk=fdfc6b9879d9a60bf61b63ab3b25749a&parentPageUrl=https://datascientest.com/rdv-alternance" />
        </div>
      </Container>
      <PreFooterOmnes />
      <Footer />
    </React.Fragment>
  );
};

export default Rdv;
