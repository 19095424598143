import React from "react";

const HubSpotMeetingIframe = ({
  source = "",
  width = "100%",
  height = "636",
}: {
  source: string;
  width?: string;
  height?: string;
}) => (
  <iframe title="hubspot meeting" width={width} height={height} src={source} />
);

export default HubSpotMeetingIframe;
