const ChevronRight = ({
  color = "black",
  classes = "me-2",
  height = "1em",
}: {
  color?: string;
  classes?: string;
  height?: string;
}) => {
  return (
    <svg
      className={classes}
      height={height}
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1.2998L5.3501 5.6499L1 10" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default ChevronRight;
