import React from "react";

const PinLocation = ({
  color,
  classes = "me-2",
  height = "20px",
}: {
  color?: string;
  classes?: string;
  height?: string;
}) => {
  return (
    <svg
      className={classes}
      height={height}
      width={height}
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3979 1.30437C8.18181 2.11007 8.66699 3.22392 8.66699 4.45317C8.66699 5.96837 8.12862 7.66905 7.33676 9.19633C6.50255 10.8037 5.3922 12.2105 4.33411 13C3.27607 12.2105 2.16474 10.8038 1.33056 9.19633C0.538697 7.66898 0.000326157 5.96837 0.000326157 4.45317C0.000326157 3.2238 0.485484 2.10991 1.26942 1.30437C2.05333 0.498668 3.13705 0 4.33418 0C5.53029 0 6.61415 0.498645 7.3979 1.30437ZM5.76506 2.9836C6.12987 3.35856 6.35574 3.87854 6.35574 4.45319C6.35574 5.02784 6.12996 5.54779 5.76409 5.92477C5.39927 6.29973 4.89335 6.53187 4.33425 6.53187C3.77514 6.53187 3.26925 6.29982 2.90247 5.92277C2.53765 5.54781 2.31178 5.02784 2.31178 4.45319C2.31178 3.87854 2.53756 3.35858 2.90441 2.9826C3.26923 2.60765 3.77514 2.3755 4.33425 2.3755C4.89335 2.3755 5.39925 2.60756 5.76506 2.9836ZM6.93371 4.45307C6.93371 3.71729 6.64282 3.04941 6.1715 2.56391C5.70019 2.08049 5.05029 1.78151 4.33441 1.78151C3.61756 1.78151 2.96765 2.08049 2.49537 2.56491C2.02503 3.04932 1.73414 3.71729 1.73414 4.45307C1.73414 5.18985 2.02503 5.85782 2.4974 6.34323C2.96871 6.82665 3.61862 7.12563 4.3345 7.12563C5.05038 7.12563 5.70019 6.82665 6.17256 6.34114C6.64291 5.85673 6.93371 5.18885 6.93371 4.45307Z"
        fill={color}
      />
    </svg>
  );
};

export default PinLocation;
