import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";

import "./DstNavbar.scss";
import { Container, Dropdown } from "react-bootstrap";
import DstButton from "../DstButton/DstButton";
import CardHeader from "../CardHeader/CardHeader";
import DropdownMenuItem from "../DropdownMenuItem/DropdownMenuItem";

const DstNavbar = ({
  theme = "white",
  buttonLink = "/rdv",
}: {
  theme?: string;
  buttonLink?: string;
}) => {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState("Cursus data");
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleMenuItemClick = (menuItem: string) => {
    setSelectedMenuItem(menuItem);
    setIsOpen(false);
  };

  const menuItemsFormations = [
    { title: "Cursus data" },
    { title: "Certifications éditeurs" },
    { title: "Cursus cybersécurité" },
    { title: "Cursus dev" },
    { title: "Essentiels de la data" },
  ];

  const menuItemsAlternance = [{ title: "Formations en alternance" }];

  const menuItemsEcole = [{ title: "Notre école" }];

  return (
    <Navbar
      sticky="top"
      bg={theme}
      id="navbar"
      className="p-3 px-md-5 border-bottom"
      expand="xl"
    >
      <Container className="d-flex justify-content-between align-items-center position-relative">
        <Navbar.Brand>
          <Link to="/">
            <img
              src="/assets/images/dst-logo.png"
              alt="DataScientest"
              height="30px"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggle}>
          {isOpen ? (
            <img src="/assets/icons/close.svg" alt="close" />
          ) : (
            <img src="/assets/icons/burger.svg" alt="open menu" />
          )}
        </Navbar.Toggle>

        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto mt-2 xs-regular">
            <Dropdown className="dropdown menu-large nav-item">
              <span onClick={() => setSelectedMenuItem("Cursus data")}>
                <Dropdown.Toggle as={Nav.Link}>Nos formations</Dropdown.Toggle>
              </span>
              <Dropdown.Menu className="dropdown-menu megamenu">
                <div className="d-md-flex ">
                  <div className="all-tab mb-1 mb-md-3 xs-regular col col-md-3 col-lg-2">
                    {menuItemsFormations.map((menuItem) => (
                      <DropdownMenuItem
                        key={menuItem.title}
                        title={menuItem.title}
                        isActive={selectedMenuItem === menuItem.title}
                        clickFunction={() =>
                          handleMenuItemClick(menuItem.title)
                        }
                      />
                    ))}
                  </div>
                  {selectedMenuItem === "Cursus data" && (
                    <div className="col col-md-9 col-lg-10 m-0">
                      <CardHeader
                        title="Data Scientist"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-ds.png"
                        backgroundColor="beige"
                        bootcamp
                        partTime
                        link="/formation-data-scientist"
                      />
                      <CardHeader
                        title="Data Analyst"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-da.png"
                        backgroundColor="tinted-red"
                        bootcamp
                        partTime
                        link="/formation-data-analyst"
                      />
                      <CardHeader
                        title="Data Engineer"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-de.png"
                        backgroundColor="tinted-blue"
                        bootcamp
                        partTime
                        link="/formation-data-engineer"
                      />
                      <CardHeader
                        title="Machine Learning Engineer"
                        backgroundColor="pink"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-mle.png"
                        bootcamp
                        partTime
                        link="/formation-machine-learning-engineer"
                      />
                      <CardHeader
                        title="Deep Learning"
                        backgroundColor="violet"
                        image="https://datascientest.com/wp-content/uploads/2022/10/header-dl.png"
                        partTime
                        link="/formation-deep-learning"
                      />
                      <CardHeader
                        title="MLOps"
                        backgroundColor="pink"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-mle.png"
                        partTime
                        link="/formation-ml-ops"
                      />
                      <CardHeader
                        title="Marketing Digital & Data"
                        backgroundColor="tinted-blue"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-da.png"
                        partTime
                        link="/formation-marketing-digital-et-data"
                      />
                      <CardHeader
                        title="Data Product Manager"
                        image="https://datascientest.com/wp-content/uploads/2022/10/data-product.png"
                        backgroundColor="orange"
                        partTime
                        link="/formation-data-product-manager"
                      />
                    </div>
                  )}
                  {selectedMenuItem === "Certifications éditeurs" && (
                    <div className="col col-md-9 col-lg-10 m-0">
                      <CardHeader
                        title="Microsoft Azure"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-azure.png"
                        backgroundColor="gray"
                        bootcamp
                        bootcampText="5 jours"
                        link="/formation-microsoft-azure"
                      />
                      <CardHeader
                        title="Amazon AWS"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-aws.png"
                        backgroundColor="gray"
                        bootcamp
                        bootcampText="4 jours"
                        link="/formation-aws"
                      />
                      <CardHeader
                        title="Microsoft Power BI"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-pbi.png"
                        backgroundColor="gray"
                        bootcamp
                        bootcampText="2 à 5 jours"
                        link="/formation-power-bi"
                      />
                    </div>
                  )}
                  {selectedMenuItem === "Cursus cybersécurité" && (
                    <div className="col col-md-9 col-lg-10 m-0">
                      <CardHeader
                        title="Cybersécurité - Analyser les incidents détectés"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-cyber.png"
                        backgroundColor="blue-azure"
                        bootcamp
                        partTime
                        link="/formation-analyste-cybersecurite"
                      />
                    </div>
                  )}
                  {selectedMenuItem === "Cursus dev" && (
                    <div className="col col-md-9 col-lg-10 m-0">
                      <CardHeader
                        title="Ingénieur DevOps"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-devops.png"
                        backgroundColor="blue-azure"
                        bootcamp
                        partTime
                        link="/formation-devops"
                      />
                      <CardHeader
                        title="Administrateur Systèmes, Réseaux et Cloud"
                        image="https://datascientest.com/wp-content/uploads/2023/01/business-it.png"
                        backgroundColor="blue-azure"
                        bootcamp
                        partTime
                        link="/formation-administrateur-systemes-reseaux-et-cloud"
                      />
                    </div>
                  )}
                  {selectedMenuItem === "Essentiels de la data" && (
                    <div className="col col-md-9 col-lg-10 m-0">
                      <CardHeader
                        title="Microsoft Power BI"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-pbi.png"
                        backgroundColor="gray"
                        bootcamp
                        bootcampText="2 à 5 jours"
                        link="/formation-power-bi"
                      />
                      <CardHeader
                        title="SQL"
                        image="https://datascientest.com/wp-content/uploads/2023/07/SQL.png"
                        backgroundColor="gray"
                        bootcamp
                        bootcampText="3 jours"
                        link="/formation-sql"
                      />
                      <CardHeader
                        title="Excel"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-excel.png"
                        backgroundColor="gray"
                        bootcamp
                        bootcampText="6 jours"
                        link="/formation-excel"
                      />
                      <CardHeader
                        title="Data Management"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-dm.png"
                        backgroundColor="gray"
                        partTime
                        link="/formation-data-management"
                      />
                      <CardHeader
                        title="Prompt Engineering
                        & Generative AI"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-de.png"
                        backgroundColor="gray"
                        bootcamp
                        bootcampText="2 à 5 jours"
                        link="/formation-prompt-engineering"
                      />
                    </div>
                  )}
                </div>
                <div className="mx-2 my-3 xs-regular">
                  <a
                    href="https://datascientest.com/formations-data"
                    className="black-font"
                  >
                    Voir toutes nos formations
                  </a>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="dropdown menu-large nav-item">
              <span
                onClick={() => setSelectedMenuItem("Formations en alternance")}
              >
                <Dropdown.Toggle as={Nav.Link}>Alternance</Dropdown.Toggle>
              </span>
              <Dropdown.Menu className="dropdown-menu megamenu">
                <div className="d-sm-flex">
                  <div className="all-tab mb-3 xs-regular">
                    {menuItemsAlternance.map((menuItem) => (
                      <DropdownMenuItem
                        key={menuItem.title}
                        title={menuItem.title}
                        isActive={selectedMenuItem === menuItem.title}
                        clickFunction={() =>
                          handleMenuItemClick(menuItem.title)
                        }
                      />
                    ))}
                  </div>
                  {selectedMenuItem === "Formations en alternance" && (
                    <div className="col col-md-9 col-lg-10 m-0">
                      <CardHeader
                        title="Ingénieur DevOps"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-devops.png"
                        backgroundColor="blue-azure"
                        alternance
                        alternanceText="1 an en alternance"
                        link="/formation-devops-alternance"
                      />

                      <CardHeader
                        title="Ingénieur en IA"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-mle.png"
                        backgroundColor="violet"
                        alternance
                        alternanceText="2 ans en alternance"
                        link="/formation-ingenieur-en-intelligence-artificielle"
                      />

                      <CardHeader
                        title="Data Engineer"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-de.png"
                        backgroundColor="tinted-blue"
                        alternance
                        alternanceText="2 ans en alternance"
                        link="/formation-data-engineer-alternance"
                      />
                    </div>
                  )}
                </div>
                <div className="mx-2 my-3 xs-regular">
                  <a
                    href="https://datascientest.com/formations-data"
                    className="black-font"
                  >
                    Voir toutes nos formations
                  </a>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="dropdown menu-large nav-item">
              <span onClick={() => setSelectedMenuItem("Notre école")}>
                <Dropdown.Toggle as={Nav.Link}>Notre école</Dropdown.Toggle>
              </span>
              <Dropdown.Menu className="dropdown-menu megamenu">
                <div className="d-sm-flex">
                  <div className="col-sm-4 col-lg-3 col-xl-2 me-sm-3">
                    <div className="all-tab mb-3 xs-regular">
                      {menuItemsEcole.map((menuItem) => (
                        <DropdownMenuItem
                          key={menuItem.title}
                          title={menuItem.title}
                          isActive={selectedMenuItem === menuItem.title}
                          clickFunction={() =>
                            handleMenuItemClick(menuItem.title)
                          }
                        />
                      ))}
                    </div>
                  </div>
                  {selectedMenuItem === "Notre école" && (
                    <div className="col col-md-9 col-lg-10 m-0">
                      <CardHeader
                        title="ADN pédagogique"
                        image="https://datascientest.com/wp-content/uploads/2022/08/header-de.png"
                        backgroundColor="tinted-red"
                        link="/a-propos"
                      />

                      <CardHeader
                        title="Engagements"
                        image="https://datascientest.com/wp-content/uploads/2023/06/header-carriere.png"
                        backgroundColor="tinted-green"
                        link="/nos-engagements"
                      />

                      <CardHeader
                        title="Carrière"
                        image="https://datascientest.com/wp-content/uploads/2023/06/header-engagement.png"
                        backgroundColor="tinted-blue"
                        link="/carriere-management"
                      />

                      <CardHeader
                        title="Événements"
                        image="https://datascientest.com/wp-content/uploads/2023/06/header-evenement.png"
                        backgroundColor="beige"
                        link="/events"
                      />
                    </div>
                  )}
                </div>
                <div className="mx-2 my-3 xs-regular"> </div>
              </Dropdown.Menu>
            </Dropdown>
            <a
              href="https://datascientest.com/blog-data-ia-actualites"
              className="xs-regular nav-link me-2"
            >
              Blog
            </a>
            <a
              href="https://datascientest.com/formation-en-entreprise"
              className="xs-regular nav-link me-2"
            >
              Vous êtes une entreprise ?
            </a>
          </Nav>
          <DstButton
            btnClass="btn btn-primary"
            value="Prendre rendez-vous"
            clickFunction={() => navigate(buttonLink)}
          />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default DstNavbar;
