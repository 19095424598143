import React from "react";
import { Container, Nav, Image, Row, Col } from "react-bootstrap";
import DstButton from "../DstButton/DstButton";

import "./DstFooter.scss";

interface NavItem {
  title: string;
  href: string;
}

const navItems: NavItem[] = [
  {
    title: "Offres d’emploi",
    href: "https://datascientest.welcomekit.co/#block-109314",
  },
  {
    title: "Notre équipe",
    href: "https://www.welcometothejungle.com/fr/companies/datascientest/team-2",
  },
  {
    title: "Retours d’expérience",
    href: "https://www.switchup.org/bootcamps/datascientest#:~:text=%22Je%20recommande%20fortement%20cette%20formation,permis%20de%20monter%20en%20comp%C3%A9tences.",
  },
  { title: "Contact", href: "https://datascientest.com/rdv" },
];

const socialItems: NavItem[] = [
  { title: "Facebook", href: "https://www.facebook.com/datascientest/" },
  { title: "Twitter", href: "https://twitter.com/datascientest__" },
  {
    title: "LinkedIn",
    href: "https://www.linkedin.com/school/datascientest/mycompany/",
  },
  {
    title: "YouTube",
    href: "https://www.youtube.com/channel/UCTVTlRE_gyvZvEFJOxRZlrA",
  },
  {
    title: "Instagram",
    href: "https://www.instagram.com/datascientest/?hl=fr",
  },
  { title: "Github", href: "https://github.com/DataScientest" },
];

const infoItems: NavItem[] = [
  {
    title: "Financement et Tarifs",
    href: "https://datascientest.com/financement-et-tarifs",
  },
  {
    title: "Accueil handicap",
    href: "https://datascientest.com/accueil-handicap",
  },
  {
    title: "Foire aux questions",
    href: "https://datascientest.com/faq-data-science",
  },
  {
    title: "Mentions légales",
    href: "https://datascientest.com/mentions-legales",
  },
];

const formationItems: NavItem[] = [
  {
    title: "Data Scientist",
    href: "https://datascientest.com/formation-data-scientist",
  },
  {
    title: "Data Analyst",
    href: "https://datascientest.com/formation-data-analyst",
  },
  {
    title: "Data Engineer",
    href: "https://datascientest.com/formation-data-engineer",
  },
  {
    title: "Data Management",
    href: "https://datascientest.com/formation-data-management",
  },
  {
    title: "Cursus experts",
    href: "https://datascientest.com/formations-data#expert",
  },
  {
    title: "Formations éditeurs",
    href: "https://datascientest.com/formations-data#certif",
  },
];

interface NavItem {
  title: string;
  href: string;
}
const ColNav = ({ items, newTab }: { items: NavItem[]; newTab?: boolean }) => (
  <Col xs={6} lg className="my-2">
    <Nav className="flex-column">
      {items.map((item, index) => (
        /* eslint-disable react/jsx-no-target-blank */
        <Nav.Link
          key={index}
          className="p-0 gray-font xs-regular white-hover-link"
          href={item.href}
          target={newTab ? "_blank" : "_self"}
          rel={newTab ? "noopener noreferrer" : ""}
        >
          {item.title}
        </Nav.Link>
      ))}
    </Nav>
  </Col>
);
const Footer = () => {
  return (
    <div className="midnight-blue-background p-4">
      <Container>
        <Row className="align-items-center row d-flex justify-content-between mb-5">
          <div className="w-fit">
            <Image
              src="/assets/images/favicon.png"
              alt="favicon DST"
              height="60px"
            />
          </div>
          <div className="w-fit">
            <DstButton
              btnClass="btn btn-light mt-4"
              value="Espace apprenant"
              color="#4628dd"
              link="https://train.datascientest.com/"
              target="_blank"
            />
          </div>
        </Row>

        <Row>
          <ColNav items={navItems} newTab={true} />
          <ColNav items={socialItems} newTab={true} />
          <ColNav items={infoItems} />
          <ColNav items={formationItems} />

          <Col xs={6} lg className="d-none d-lg-block">
            <Image
              src="/assets/images/logo-qualiopi.webp"
              alt="favicon DST"
              height="100px"
            />
            <p className="white-font xxs-regular mb-1">
              La certification qualité a été délivrée au titre des catégories
              d’actions suivantes&nbsp;:
              <br />- Actions de formation
              <br />- Actions de formation par apprentissage
            </p>
            <a
              href="https://datascientest.com/wp-content/uploads/2023/06/Certificat-QUALIOPI.pdf"
              target="_blank"
              className="white-font xxs-regular"
            >
              Consulter le Certificat Qualiopi
            </a>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between align-items-end">
          <div className="w-fit">
            <p className="mb-0 mt-5 white-font fw-bold">
              Envie de suivre l’actu de DataScientest ?
            </p>
            <div className="mt-3">
              <a
                href="https://datascientest.com/blog-data-ia-actualites#formnewsletter"
                className="gray-font text-decoration-none me-3"
              >
                Inscrivez-vous à notre newsletter !
              </a>
              <DstButton
                btnClass="btn btn-primary"
                value=" S'inscrire"
                link="https://datascientest.com/blog-data-ia-actualites#formnewsletter"
                target="_blank"
              />
            </div>
          </div>
          <div className="w-fit mt-4 mt-lg-0">
            <p className="white-font mb-0">
              © {new Date().getFullYear()} DataScientest — Tous droits réservés.
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
