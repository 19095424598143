import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { JobPoei, jobspoei } from "../JobBoardPoei/JobBoardPoeiMock";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import DstButton from "../../components/DstButton/DstButton";
import ExternalLink from "../../components/DstIcon/ExternalLink";
import DstNavbar from "../../components/DstNavbar/DstNavbar";
import PreFooterOmnes from "../../components/PreFooterOmnes/PreFooterOmnes";
import Footer from "../../components/DstFooter/DstFooter";
import ChevronRight from "../../components/DstIcon/ChevronRight";
import "./JobOfferPoei.scss";

interface LocationState {
  job?: JobPoei;
}

const JobOfferPoei = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [selectedJob, setSelectedJob] = useState<JobPoei | null>(null);
  const location = useLocation();
  const [jobFound, setJobFound] = useState<boolean>(true);

  useEffect(() => {
    const stateJob = (location.state as LocationState)?.job;
    if (stateJob) {
      setSelectedJob(stateJob);
    } else {
      const matchingJob = jobspoei.find((job) => job.id === jobId);
      if (matchingJob) {
        setSelectedJob(matchingJob);
      } else {
        setJobFound(false);
      }
    }
  }, [jobId, location.state]);

  return (
    <React.Fragment>
      <DstNavbar buttonLink="/rdv-poei" />
      <div className="text-center background-offre"></div>
      <Container className="mb-5 mt-n4">
        {jobFound ? (
          <React.Fragment>
            <div
              className={`mb-3 d-md-flex justify-content-between align-items-center text-center`}
            >
              <div className="d-flex flex-column flex-md-row align-items-center">
                {selectedJob && (
                  <img
                    src={`/assets/images/entreprise/${selectedJob.logo}`}
                    alt="Logo de l'entreprise"
                    className="job-offer-logo border rounded white-background p-2"
                  />
                )}
                <div className="ms-sm-3">
                  {selectedJob && <h1>{selectedJob.jobTitle}</h1>}
                </div>
              </div>
              <DstButton
                btnClass="mt-3 mt-md-0"
                value="Je postule"
                variant="violet"
                btnImage={<ExternalLink />}
                clickFunction={() => navigate("/rdv-poei")}
              />
            </div>

            {selectedJob && (
              <Row className="mt-3 mt-md-5">
                <Col md>
                  <span>Profil recherché : </span>
                  <span
                    className="gray-font"
                    dangerouslySetInnerHTML={{
                      __html: selectedJob.profileSought,
                    }}
                  />
                </Col>
                <Col md>
                  <span>Prérequis : </span>
                  <span
                    className="gray-font"
                    dangerouslySetInnerHTML={{
                      __html: selectedJob.prerequisites,
                    }}
                  />
                </Col>
              </Row>
            )}
            {selectedJob && (
              <React.Fragment>
                <h3 className="mt-3">Objectifs</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedJob.goals,
                  }}
                />
              </React.Fragment>
            )}
            {selectedJob && (
              <React.Fragment>
                <h3 className="mt-3">Sprint</h3>
                {selectedJob.programmes.length > 0 ? (
                  <Accordion>
                    {selectedJob.programmes.map((programme, index) => (
                      <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Header>
                          {programme.duration ? (
                            <span className="duration">
                              {programme.duration}  - 
                            </span>
                          ) : null}
                          <span className="m-regular">{programme.title}</span>
                        </Accordion.Header>
                        <Accordion.Body>
                          {programme.description ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: programme.description,
                              }}
                            />
                          ) : (
                            <p>Aucune description.</p>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                ) : (
                  <p>Aucun programme disponible pour cet emploi.</p>
                )}
              </React.Fragment>
            )}
            {selectedJob && (
              <Row className="justify-content-center mt-3">
                <DstButton
                  btnClass="m-2"
                  variant="violet"
                  btnImage={<ExternalLink />}
                  value="Je postule"
                  clickFunction={() => navigate("/rdv-poei")}
                />
                <DstButton
                  btnClass="m-2"
                  variant="link"
                  color="#4628DD"
                  value="Voir les autres offres"
                  clickFunction={() => navigate("/poei")}
                  btnImage={<ChevronRight color="#4628dd" classes="ms-2" />}
                />
              </Row>
            )}
          </React.Fragment>
        ) : (
          <div className="text-center mt-5">
            <h2>Offre non disponible</h2>
            <p>
              L'offre demandée n'est pas disponible. Veuillez retourner à la
              page d'accueil pour consulter d'autres offres.
            </p>
            <DstButton
              value="Retour à l'accueil"
              clickFunction={() => navigate("/")}
            />
          </div>
        )}
      </Container>
      <PreFooterOmnes />
      <Footer />
    </React.Fragment>
  );
};

export default JobOfferPoei;
